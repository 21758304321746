// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "user_message/user_message_service.proto" (package "user_message", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MsgService } from "./user_message_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AddUserMessageResp } from "./user_message_service";
import type { UserMessage } from "../web_api/dto";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service user_message.MsgService
 */
export interface IMsgServiceClient {
    /**
     * @generated from protobuf rpc: AddUserMessage(dto.UserMessage) returns (user_message.AddUserMessageResp);
     */
    addUserMessage(input: UserMessage, options?: RpcOptions): UnaryCall<UserMessage, AddUserMessageResp>;
}
/**
 * @generated from protobuf service user_message.MsgService
 */
export class MsgServiceClient implements IMsgServiceClient, ServiceInfo {
    typeName = MsgService.typeName;
    methods = MsgService.methods;
    options = MsgService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AddUserMessage(dto.UserMessage) returns (user_message.AddUserMessageResp);
     */
    addUserMessage(input: UserMessage, options?: RpcOptions): UnaryCall<UserMessage, AddUserMessageResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserMessage, AddUserMessageResp>("unary", this._transport, method, opt, input);
    }
}
