// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as token_api from './token_api'

export class TokenApiRealClient {
  constructor(private transport: Transport) {}

  async GetUserTokenTotalApi(
    request: token_api.GetUserTokenTotalApiReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_api.GetUserTokenTotalApiResp> {
    return this.transport.request({
      path: '/api/token/get-total',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goTokenApi = new TokenApiRealClient(productionClient)
