// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "token/token_api.proto" (package "token_api", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ResponseStatus } from "../base";
import { TokenType } from "./token_entity";
/**
 * @generated from protobuf message token_api.GetUserTokenTotalApiReq
 */
export interface GetUserTokenTotalApiReq {
    /**
     * @generated from protobuf field: token.TokenType token_type = 1;
     */
    tokenType: TokenType;
}
/**
 * @generated from protobuf message token_api.GetUserTokenTotalApiResp
 */
export interface GetUserTokenTotalApiResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: int32 total = 2;
     */
    total: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenTotalApiReq$Type extends MessageType<GetUserTokenTotalApiReq> {
    constructor() {
        super("token_api.GetUserTokenTotalApiReq", [
            { no: 1, name: "token_type", kind: "enum", T: () => ["token.TokenType", TokenType] }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenTotalApiReq>): GetUserTokenTotalApiReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenType = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenTotalApiReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenTotalApiReq): GetUserTokenTotalApiReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* token.TokenType token_type */ 1:
                    message.tokenType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenTotalApiReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* token.TokenType token_type = 1; */
        if (message.tokenType !== 0)
            writer.tag(1, WireType.Varint).int32(message.tokenType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token_api.GetUserTokenTotalApiReq
 */
export const GetUserTokenTotalApiReq = new GetUserTokenTotalApiReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenTotalApiResp$Type extends MessageType<GetUserTokenTotalApiResp> {
    constructor() {
        super("token_api.GetUserTokenTotalApiResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenTotalApiResp>): GetUserTokenTotalApiResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenTotalApiResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenTotalApiResp): GetUserTokenTotalApiResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* int32 total */ 2:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenTotalApiResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token_api.GetUserTokenTotalApiResp
 */
export const GetUserTokenTotalApiResp = new GetUserTokenTotalApiResp$Type();
/**
 * @generated ServiceType for protobuf service token_api.TokenApi
 */
export const TokenApi = new ServiceType("token_api.TokenApi", [
    { name: "GetUserTokenTotalApi", options: { "api.post": "/api/token/get-total" }, I: GetUserTokenTotalApiReq, O: GetUserTokenTotalApiResp }
]);
