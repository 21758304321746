// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as token_service from './token_service'

export class TokenServiceRealClient {
  constructor(private transport: Transport) {}

  async AddToken(
    request: token_service.AddTokenReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_service.AddTokenResp> {
    return this.transport.request({
      path: '/token/add',
      method: 'POST',
      input: request,
      options
    })
  }


  async FreezeToken(
    request: token_service.FreezeTokenReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_service.FreezeTokenResp> {
    return this.transport.request({
      path: '/token/freeze',
      method: 'POST',
      input: request,
      options
    })
  }


  async ConsumeToken(
    request: token_service.ConsumeTokenReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_service.ConsumeTokenResp> {
    return this.transport.request({
      path: '/token/consume',
      method: 'POST',
      input: request,
      options
    })
  }


  async UnfreezeToken(
    request: token_service.UnfreezeTokenReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_service.UnfreezeTokenResp> {
    return this.transport.request({
      path: '/token/unfreeze',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetUserTokenOperation(
    request: token_service.GetUserTokenOperationReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_service.GetUserTokenOperationResp> {
    return this.transport.request({
      path: '/token/get-operations',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetUserTokenDetail(
    request: token_service.GetUserTokenDetailReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_service.GetUserTokenOperationResp> {
    return this.transport.request({
      path: '/token/get-details',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetUserTokenTotal(
    request: token_service.GetUserTokenTotalReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<token_service.GetUserTokenTotalResp> {
    return this.transport.request({
      path: '/token/get-total',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goTokenService = new TokenServiceRealClient(productionClient)
