'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import Link from 'next/link';
import { useEffect } from 'react';
import * as proto from '@/generated';
import { useUser } from '@/hooks/useUser';
import { cn } from '@/lib/utils';
export const tokenQueryKey = ['token'] as const;
export function useTokenQuery() {
  const {
    hasLogin
  } = useUser();
  return useQuery({
    queryKey: tokenQueryKey,
    queryFn: async () => {
      const resp = await proto.goTokenApi.GetUserTokenTotalApi({
        tokenType: proto.TokenType.TOKEN_GENERATE_WEBSITE
      });
      return resp.total;
    },
    enabled: hasLogin,
    refetchInterval: 30000,
    refetchOnWindowFocus: true
  });
}
export const TokenBar = ({
  className
}: {
  className?: string;
}): React.ReactElement => {
  const {
    data: tokenCount = 0
  } = useTokenQuery();
  const queryClient = useQueryClient();
  const {
    hasLogin
  } = useUser();
  useEffect(() => {
    if (hasLogin) {
      queryClient.invalidateQueries({
        queryKey: tokenQueryKey
      });
    }
  }, [hasLogin]);
  return <Link href="/pricing" className={cn('flex items-center', className)} data-sentry-element="Link" data-sentry-component="TokenBar" data-sentry-source-file="TokenBar.tsx">
      <div className="flex items-center rounded-lg bg-opacity-90 px-3 py-1.5 shadow-sm">
        <svg className="w-4 h-4 text-cyan-400 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" data-sentry-element="svg" data-sentry-source-file="TokenBar.tsx">
          <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" data-sentry-element="path" data-sentry-source-file="TokenBar.tsx" />
        </svg>
        <span className="font-medium text-black">{tokenCount}</span>
      </div>
    </Link>;
};