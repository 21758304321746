// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as order_api from './order_api'

export class OrderApiRealClient {
  constructor(private transport: Transport) {}

  async CreateStripeCheckoutSession(
    request: order_api.CreateStripeCheckoutSessionRequest,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<order_api.CreateStripeCheckoutSessionResponse> {
    return this.transport.request({
      path: '/api/order/stripe/checkout_session/create',
      method: 'POST',
      input: request,
      options
    })
  }


  async CheckStripePaymentStatus(
    request: order_api.CheckStripePaymentStatusRequest,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<order_api.CheckStripePaymentStatusResponse> {
    return this.transport.request({
      path: '/api/order/stripe/checkout_session/check_status',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goOrderApi = new OrderApiRealClient(productionClient)
