// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "adapter/adapter_api.proto" (package "adapter_api", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ResponseStatus } from "../base";
/**
 * @generated from protobuf message adapter_api.GetWebsitePresignedUrlReq
 */
export interface GetWebsitePresignedUrlReq {
    /**
     * @generated from protobuf field: string version_id = 1;
     */
    versionId: string;
}
/**
 * @generated from protobuf message adapter_api.GetWebsitePresignedUrlResp
 */
export interface GetWebsitePresignedUrlResp {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: base.ResponseStatus status = 255;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message adapter_api.GenWebsiteReq
 */
export interface GenWebsiteReq {
    /**
     * @generated from protobuf field: string prompt = 1;
     */
    prompt: string;
    /**
     * @generated from protobuf field: repeated string images = 2;
     */
    images: string[];
}
/**
 * @generated from protobuf message adapter_api.GenWebsiteResp
 */
export interface GenWebsiteResp {
    /**
     * @generated from protobuf field: string website_id = 1;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: string version_id = 2;
     */
    versionId: string;
    /**
     * @generated from protobuf field: base.ResponseStatus status = 255;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message adapter_api.EditWebsiteReq
 */
export interface EditWebsiteReq {
    /**
     * @generated from protobuf field: string version_id = 1;
     */
    versionId: string;
    /**
     * @generated from protobuf field: string prompt = 2;
     */
    prompt: string;
    /**
     * @generated from protobuf field: repeated string images = 3;
     */
    images: string[];
}
/**
 * @generated from protobuf message adapter_api.EditWebsiteResp
 */
export interface EditWebsiteResp {
    /**
     * @generated from protobuf field: string version_id = 1;
     */
    versionId: string;
    /**
     * @generated from protobuf field: base.ResponseStatus status = 255;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message adapter_api.GetWebsiteSSEReq
 */
export interface GetWebsiteSSEReq {
    /**
     * @generated from protobuf field: string prompt = 1;
     */
    prompt: string;
    /**
     * @generated from protobuf field: repeated string images = 2;
     */
    images: string[];
}
/**
 * @generated from protobuf message adapter_api.GetWebsiteSSEResp
 */
export interface GetWebsiteSSEResp {
    /**
     * @generated from protobuf field: string version_id = 1;
     */
    versionId: string;
    /**
     * @generated from protobuf field: string website_id = 2;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: base.ResponseStatus status = 255;
     */
    status?: ResponseStatus;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsitePresignedUrlReq$Type extends MessageType<GetWebsitePresignedUrlReq> {
    constructor() {
        super("adapter_api.GetWebsitePresignedUrlReq", [
            { no: 1, name: "version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsitePresignedUrlReq>): GetWebsitePresignedUrlReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.versionId = "";
        if (value !== undefined)
            reflectionMergePartial<GetWebsitePresignedUrlReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsitePresignedUrlReq): GetWebsitePresignedUrlReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version_id */ 1:
                    message.versionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsitePresignedUrlReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version_id = 1; */
        if (message.versionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.versionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.GetWebsitePresignedUrlReq
 */
export const GetWebsitePresignedUrlReq = new GetWebsitePresignedUrlReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsitePresignedUrlResp$Type extends MessageType<GetWebsitePresignedUrlResp> {
    constructor() {
        super("adapter_api.GetWebsitePresignedUrlResp", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 255, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<GetWebsitePresignedUrlResp>): GetWebsitePresignedUrlResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<GetWebsitePresignedUrlResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsitePresignedUrlResp): GetWebsitePresignedUrlResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* base.ResponseStatus status */ 255:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsitePresignedUrlResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* base.ResponseStatus status = 255; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(255, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.GetWebsitePresignedUrlResp
 */
export const GetWebsitePresignedUrlResp = new GetWebsitePresignedUrlResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenWebsiteReq$Type extends MessageType<GenWebsiteReq> {
    constructor() {
        super("adapter_api.GenWebsiteReq", [
            { no: 1, name: "prompt", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field": { description: "\u751F\u6210\u7F51\u7AD9\u7684\u63D0\u793A\u63CF\u8FF0", example: "\"Create a simple company website\"" } } },
            { no: 2, name: "images", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field": { description: "\u76F8\u5173\u56FE\u7247\u7684URL\u5217\u8868" } } }
        ], { "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema": { jsonSchema: { title: "\u751F\u6210\u7F51\u7AD9\u8BF7\u6C42", description: "\u5305\u542B\u751F\u6210\u7F51\u7AD9\u6240\u9700\u7684\u63D0\u793A\u548C\u56FE\u7247\u4FE1\u606F", required: ["prompt"] } } });
    }
    create(value?: PartialMessage<GenWebsiteReq>): GenWebsiteReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prompt = "";
        message.images = [];
        if (value !== undefined)
            reflectionMergePartial<GenWebsiteReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenWebsiteReq): GenWebsiteReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string prompt */ 1:
                    message.prompt = reader.string();
                    break;
                case /* repeated string images */ 2:
                    message.images.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenWebsiteReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string prompt = 1; */
        if (message.prompt !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.prompt);
        /* repeated string images = 2; */
        for (let i = 0; i < message.images.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.images[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.GenWebsiteReq
 */
export const GenWebsiteReq = new GenWebsiteReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenWebsiteResp$Type extends MessageType<GenWebsiteResp> {
    constructor() {
        super("adapter_api.GenWebsiteResp", [
            { no: 1, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 255, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<GenWebsiteResp>): GenWebsiteResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.websiteId = "";
        message.versionId = "";
        if (value !== undefined)
            reflectionMergePartial<GenWebsiteResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenWebsiteResp): GenWebsiteResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string website_id */ 1:
                    message.websiteId = reader.string();
                    break;
                case /* string version_id */ 2:
                    message.versionId = reader.string();
                    break;
                case /* base.ResponseStatus status */ 255:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenWebsiteResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string website_id = 1; */
        if (message.websiteId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.websiteId);
        /* string version_id = 2; */
        if (message.versionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.versionId);
        /* base.ResponseStatus status = 255; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(255, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.GenWebsiteResp
 */
export const GenWebsiteResp = new GenWebsiteResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditWebsiteReq$Type extends MessageType<EditWebsiteReq> {
    constructor() {
        super("adapter_api.EditWebsiteReq", [
            { no: 1, name: "version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prompt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "images", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EditWebsiteReq>): EditWebsiteReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.versionId = "";
        message.prompt = "";
        message.images = [];
        if (value !== undefined)
            reflectionMergePartial<EditWebsiteReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditWebsiteReq): EditWebsiteReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version_id */ 1:
                    message.versionId = reader.string();
                    break;
                case /* string prompt */ 2:
                    message.prompt = reader.string();
                    break;
                case /* repeated string images */ 3:
                    message.images.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditWebsiteReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version_id = 1; */
        if (message.versionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.versionId);
        /* string prompt = 2; */
        if (message.prompt !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.prompt);
        /* repeated string images = 3; */
        for (let i = 0; i < message.images.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.images[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.EditWebsiteReq
 */
export const EditWebsiteReq = new EditWebsiteReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditWebsiteResp$Type extends MessageType<EditWebsiteResp> {
    constructor() {
        super("adapter_api.EditWebsiteResp", [
            { no: 1, name: "version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 255, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<EditWebsiteResp>): EditWebsiteResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.versionId = "";
        if (value !== undefined)
            reflectionMergePartial<EditWebsiteResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditWebsiteResp): EditWebsiteResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version_id */ 1:
                    message.versionId = reader.string();
                    break;
                case /* base.ResponseStatus status */ 255:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditWebsiteResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version_id = 1; */
        if (message.versionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.versionId);
        /* base.ResponseStatus status = 255; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(255, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.EditWebsiteResp
 */
export const EditWebsiteResp = new EditWebsiteResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteSSEReq$Type extends MessageType<GetWebsiteSSEReq> {
    constructor() {
        super("adapter_api.GetWebsiteSSEReq", [
            { no: 1, name: "prompt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "images", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteSSEReq>): GetWebsiteSSEReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prompt = "";
        message.images = [];
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteSSEReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteSSEReq): GetWebsiteSSEReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string prompt */ 1:
                    message.prompt = reader.string();
                    break;
                case /* repeated string images */ 2:
                    message.images.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteSSEReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string prompt = 1; */
        if (message.prompt !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.prompt);
        /* repeated string images = 2; */
        for (let i = 0; i < message.images.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.images[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.GetWebsiteSSEReq
 */
export const GetWebsiteSSEReq = new GetWebsiteSSEReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWebsiteSSEResp$Type extends MessageType<GetWebsiteSSEResp> {
    constructor() {
        super("adapter_api.GetWebsiteSSEResp", [
            { no: 1, name: "version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 255, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<GetWebsiteSSEResp>): GetWebsiteSSEResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.versionId = "";
        message.websiteId = "";
        if (value !== undefined)
            reflectionMergePartial<GetWebsiteSSEResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWebsiteSSEResp): GetWebsiteSSEResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version_id */ 1:
                    message.versionId = reader.string();
                    break;
                case /* string website_id */ 2:
                    message.websiteId = reader.string();
                    break;
                case /* base.ResponseStatus status */ 255:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWebsiteSSEResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version_id = 1; */
        if (message.versionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.versionId);
        /* string website_id = 2; */
        if (message.websiteId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.websiteId);
        /* base.ResponseStatus status = 255; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(255, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message adapter_api.GetWebsiteSSEResp
 */
export const GetWebsiteSSEResp = new GetWebsiteSSEResp$Type();
/**
 * @generated ServiceType for protobuf service adapter_api.AdapterApi
 */
export const AdapterApi = new ServiceType("adapter_api.AdapterApi", [
    { name: "GenWebsite", options: { "api.post": "/api/adapter/gen-website", "google.api.http": { post: "/api/adapter/gen-website", body: "*" }, "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation": { tags: ["website"], summary: "\u751F\u6210\u7F51\u7AD9", description: "\u6839\u636E\u63D0\u793A\u548C\u56FE\u7247\u751F\u6210\u7F51\u7AD9" } }, I: GenWebsiteReq, O: GenWebsiteResp },
    { name: "EditWebsite", options: { "api.post": "/api/adapter/edit-website", "google.api.http": { post: "/api/adapter/edit-website", body: "*" } }, I: EditWebsiteReq, O: EditWebsiteResp },
    { name: "GetWebsiteSSE", serverStreaming: true, options: { "api.get": "/api/adapter/get-website-sse", "google.api.http": { get: "/api/adapter/get-website-sse" } }, I: GetWebsiteSSEReq, O: GetWebsiteSSEResp },
    { name: "GetWebsitePresignedUrl", options: { "api.post": "/api/adapter/get-website-presigned-url", "google.api.http": { post: "/api/adapter/get-website-presigned-url", body: "*" } }, I: GetWebsitePresignedUrlReq, O: GetWebsitePresignedUrlResp }
]);
