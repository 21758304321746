// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "order/order_api.proto" (package "order_api", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { OrderApi } from "./order_api";
import type { CheckStripePaymentStatusResponse } from "./order_api";
import type { CheckStripePaymentStatusRequest } from "./order_api";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CreateStripeCheckoutSessionResponse } from "./order_api";
import type { CreateStripeCheckoutSessionRequest } from "./order_api";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service order_api.OrderApi
 */
export interface IOrderApiClient {
    /**
     * @generated from protobuf rpc: CreateStripeCheckoutSession(order_api.CreateStripeCheckoutSessionRequest) returns (order_api.CreateStripeCheckoutSessionResponse);
     */
    createStripeCheckoutSession(input: CreateStripeCheckoutSessionRequest, options?: RpcOptions): UnaryCall<CreateStripeCheckoutSessionRequest, CreateStripeCheckoutSessionResponse>;
    /**
     * @generated from protobuf rpc: CheckStripePaymentStatus(order_api.CheckStripePaymentStatusRequest) returns (order_api.CheckStripePaymentStatusResponse);
     */
    checkStripePaymentStatus(input: CheckStripePaymentStatusRequest, options?: RpcOptions): UnaryCall<CheckStripePaymentStatusRequest, CheckStripePaymentStatusResponse>;
}
/**
 * @generated from protobuf service order_api.OrderApi
 */
export class OrderApiClient implements IOrderApiClient, ServiceInfo {
    typeName = OrderApi.typeName;
    methods = OrderApi.methods;
    options = OrderApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateStripeCheckoutSession(order_api.CreateStripeCheckoutSessionRequest) returns (order_api.CreateStripeCheckoutSessionResponse);
     */
    createStripeCheckoutSession(input: CreateStripeCheckoutSessionRequest, options?: RpcOptions): UnaryCall<CreateStripeCheckoutSessionRequest, CreateStripeCheckoutSessionResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateStripeCheckoutSessionRequest, CreateStripeCheckoutSessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckStripePaymentStatus(order_api.CheckStripePaymentStatusRequest) returns (order_api.CheckStripePaymentStatusResponse);
     */
    checkStripePaymentStatus(input: CheckStripePaymentStatusRequest, options?: RpcOptions): UnaryCall<CheckStripePaymentStatusRequest, CheckStripePaymentStatusResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckStripePaymentStatusRequest, CheckStripePaymentStatusResponse>("unary", this._transport, method, opt, input);
    }
}
