// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "order/order_api.proto" (package "order_api", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ResponseStatus } from "../base";
/**
 * @generated from protobuf message order_api.CreateStripeCheckoutSessionRequest
 */
export interface CreateStripeCheckoutSessionRequest {
    /**
     * @generated from protobuf field: string product_id = 1;
     */
    productId: string;
}
/**
 * @generated from protobuf message order_api.CreateStripeCheckoutSessionResponse
 */
export interface CreateStripeCheckoutSessionResponse {
    /**
     * @generated from protobuf field: string stripe_checkout_session_id = 1;
     */
    stripeCheckoutSessionId: string;
    /**
     * @generated from protobuf field: string stripe_checkout_url = 2;
     */
    stripeCheckoutUrl: string;
    /**
     * @generated from protobuf field: base.ResponseStatus status = 255;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message order_api.CheckStripePaymentStatusRequest
 */
export interface CheckStripePaymentStatusRequest {
    /**
     * @generated from protobuf field: string stripe_checkout_session_id = 1;
     */
    stripeCheckoutSessionId: string;
}
/**
 * @generated from protobuf message order_api.CheckStripePaymentStatusResponse
 */
export interface CheckStripePaymentStatusResponse {
    /**
     * @generated from protobuf field: order_api.PaymentStatus payment_status = 1;
     */
    paymentStatus: PaymentStatus;
    /**
     * @generated from protobuf field: base.ResponseStatus status = 255;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf enum order_api.PaymentStatus
 */
export enum PaymentStatus {
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_SUCCESS = 1;
     */
    SUCCESS = 1,
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_PENDING = 2;
     */
    PENDING = 2,
    /**
     * @generated from protobuf enum value: PAYMENT_STATUS_FAILED = 3;
     */
    FAILED = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateStripeCheckoutSessionRequest$Type extends MessageType<CreateStripeCheckoutSessionRequest> {
    constructor() {
        super("order_api.CreateStripeCheckoutSessionRequest", [
            { no: 1, name: "product_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field": { description: "\u4EA7\u54C1ID", example: "\"prod_xxx\"" } } }
        ], { "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_schema": { jsonSchema: { title: "\u521B\u5EFA\u652F\u4ED8\u4F1A\u8BDD\u8BF7\u6C42", description: "\u5305\u542B\u521B\u5EFA\u652F\u4ED8\u4F1A\u8BDD\u6240\u9700\u7684\u4EA7\u54C1\u4FE1\u606F", required: ["product_id"] } } });
    }
    create(value?: PartialMessage<CreateStripeCheckoutSessionRequest>): CreateStripeCheckoutSessionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.productId = "";
        if (value !== undefined)
            reflectionMergePartial<CreateStripeCheckoutSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateStripeCheckoutSessionRequest): CreateStripeCheckoutSessionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string product_id */ 1:
                    message.productId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateStripeCheckoutSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string product_id = 1; */
        if (message.productId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.productId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_api.CreateStripeCheckoutSessionRequest
 */
export const CreateStripeCheckoutSessionRequest = new CreateStripeCheckoutSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateStripeCheckoutSessionResponse$Type extends MessageType<CreateStripeCheckoutSessionResponse> {
    constructor() {
        super("order_api.CreateStripeCheckoutSessionResponse", [
            { no: 1, name: "stripe_checkout_session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field": { description: "Stripe \u652F\u4ED8\u4F1A\u8BDD ID" } } },
            { no: 2, name: "stripe_checkout_url", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_field": { description: "Stripe \u652F\u4ED8\u94FE\u63A5" } } },
            { no: 255, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<CreateStripeCheckoutSessionResponse>): CreateStripeCheckoutSessionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.stripeCheckoutSessionId = "";
        message.stripeCheckoutUrl = "";
        if (value !== undefined)
            reflectionMergePartial<CreateStripeCheckoutSessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateStripeCheckoutSessionResponse): CreateStripeCheckoutSessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_checkout_session_id */ 1:
                    message.stripeCheckoutSessionId = reader.string();
                    break;
                case /* string stripe_checkout_url */ 2:
                    message.stripeCheckoutUrl = reader.string();
                    break;
                case /* base.ResponseStatus status */ 255:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateStripeCheckoutSessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_checkout_session_id = 1; */
        if (message.stripeCheckoutSessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeCheckoutSessionId);
        /* string stripe_checkout_url = 2; */
        if (message.stripeCheckoutUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripeCheckoutUrl);
        /* base.ResponseStatus status = 255; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(255, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_api.CreateStripeCheckoutSessionResponse
 */
export const CreateStripeCheckoutSessionResponse = new CreateStripeCheckoutSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckStripePaymentStatusRequest$Type extends MessageType<CheckStripePaymentStatusRequest> {
    constructor() {
        super("order_api.CheckStripePaymentStatusRequest", [
            { no: 1, name: "stripe_checkout_session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckStripePaymentStatusRequest>): CheckStripePaymentStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.stripeCheckoutSessionId = "";
        if (value !== undefined)
            reflectionMergePartial<CheckStripePaymentStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckStripePaymentStatusRequest): CheckStripePaymentStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_checkout_session_id */ 1:
                    message.stripeCheckoutSessionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckStripePaymentStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_checkout_session_id = 1; */
        if (message.stripeCheckoutSessionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeCheckoutSessionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_api.CheckStripePaymentStatusRequest
 */
export const CheckStripePaymentStatusRequest = new CheckStripePaymentStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckStripePaymentStatusResponse$Type extends MessageType<CheckStripePaymentStatusResponse> {
    constructor() {
        super("order_api.CheckStripePaymentStatusResponse", [
            { no: 1, name: "payment_status", kind: "enum", T: () => ["order_api.PaymentStatus", PaymentStatus, "PAYMENT_STATUS_"] },
            { no: 255, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<CheckStripePaymentStatusResponse>): CheckStripePaymentStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckStripePaymentStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckStripePaymentStatusResponse): CheckStripePaymentStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* order_api.PaymentStatus payment_status */ 1:
                    message.paymentStatus = reader.int32();
                    break;
                case /* base.ResponseStatus status */ 255:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckStripePaymentStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* order_api.PaymentStatus payment_status = 1; */
        if (message.paymentStatus !== 0)
            writer.tag(1, WireType.Varint).int32(message.paymentStatus);
        /* base.ResponseStatus status = 255; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(255, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_api.CheckStripePaymentStatusResponse
 */
export const CheckStripePaymentStatusResponse = new CheckStripePaymentStatusResponse$Type();
/**
 * @generated ServiceType for protobuf service order_api.OrderApi
 */
export const OrderApi = new ServiceType("order_api.OrderApi", [
    { name: "CreateStripeCheckoutSession", options: { "api.post": "/api/order/stripe/checkout_session/create", "google.api.http": { post: "/api/order/stripe/checkout_session/create", body: "*" }, "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation": { tags: ["payment"], summary: "\u521B\u5EFA Stripe \u652F\u4ED8\u4F1A\u8BDD", description: "\u521B\u5EFA Stripe \u652F\u4ED8\u94FE\u63A5\u7528\u4E8E\u4EA7\u54C1\u8D2D\u4E70" } }, I: CreateStripeCheckoutSessionRequest, O: CreateStripeCheckoutSessionResponse },
    { name: "CheckStripePaymentStatus", options: { "api.post": "/api/order/stripe/checkout_session/check_status", "google.api.http": { post: "/api/order/stripe/checkout_session/check_status", body: "*" }, "grpc.gateway.protoc_gen_openapiv2.options.openapiv2_operation": { tags: ["payment"], summary: "\u68C0\u67E5 Stripe \u652F\u4ED8\u72B6\u6001", description: "\u68C0\u67E5 Stripe \u652F\u4ED8\u72B6\u6001" } }, I: CheckStripePaymentStatusRequest, O: CheckStripePaymentStatusResponse }
]);
