'use client'

import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { toast } from 'react-hot-toast'

import { createClient } from '../supabase/client'

const supabase = createClient()

// 方式 1：使用 interface
interface WithStatus {
  status: { code: number; message: string }
  [key: string]: any // 允许任何其他属性
}

export interface Transport {
  request<I, O>(params: RequestParams<I>): Promise<O>
}

interface RequestParams<REQ> {
  path: string
  method: string
  input: REQ
  options?: {
    silent?: boolean | number[] // 可以是完全静默，或者指定静默的错误码
  }
}

export class GoClient {
  private client

  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL: baseURL,
      timeout: 120 * 1000
    })

    this.client.interceptors.request.use(async (config) => {
      const {
        data: { session }
      } = await supabase.auth.getSession()
      if (session?.access_token) {
        config.headers.Authorization = `Bearer ${session.access_token}`
      }
      return config
    })
  }

  async request<REQ, RES>(params: RequestParams<REQ>): Promise<RES> {
    const snakeCaseInput = decamelizeKeys(params.input)

    const response = await this.client.request<RES>({
      url: params.path,
      method: params.method,
      data: snakeCaseInput
    })

    const camelCaseData = camelizeKeys(response.data) as WithStatus
    if (!camelCaseData.status) {
      const error = { code: -1, message: 'No status in response' }
      if (!this.shouldBeSilent(-1, params.options?.silent)) {
        toast.error(error.message)
      }
      throw new Error(error.message)
    }

    if (camelCaseData.status.code === 401) {
      await supabase.auth.signOut()
      window.location.href = '/login'
      return null as any
    }

    if (camelCaseData.status.code !== 0) {
      if (!this.shouldBeSilent(camelCaseData.status.code, params.options?.silent)) {
        toast.error(camelCaseData.status.message)
      }
    }
    return camelCaseData as RES
  }

  private shouldBeSilent(errorCode: number, silent?: boolean | number[]): boolean {
    if (silent === undefined) return false
    if (silent === true) return true
    return Array.isArray(silent) && silent.includes(errorCode)
  }
}

// 创建两个预配置的客户端实例
export const productionClient = new GoClient(process.env.NEXT_PUBLIC_BACKEND_API_URL || '')
export const localClient = new GoClient(
  process.env.NEXT_PUBLIC_BACKEND_LOCAL_API_URL || process.env.NEXT_PUBLIC_BACKEND_API_URL || ''
)
