// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as user_message_api from './user_message_api'

export class MsgApiServiceRealClient {
  constructor(private transport: Transport) {}

  async GetUserAllMessageList(
    request: user_message_api.Empty,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message_api.GetMsgListResp> {
    return this.transport.request({
      path: '/api/user-message/all',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetUserMessageList(
    request: user_message_api.GetMsgListReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message_api.GetMsgListResp> {
    return this.transport.request({
      path: '/api/user-message/page',
      method: 'POST',
      input: request,
      options
    })
  }


  async ReadUserMessage(
    request: user_message_api.ReadUserMessageReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message_api.ReadUserMessageResp> {
    return this.transport.request({
      path: '/api/user-message/read',
      method: 'POST',
      input: request,
      options
    })
  }


  async DeleteUserMessage(
    request: user_message_api.DeleteUserMessageReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message_api.DeleteUserMessageResp> {
    return this.transport.request({
      path: '/api/user-message/delete',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goMsgApiService = new MsgApiServiceRealClient(productionClient)
