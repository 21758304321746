// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "token/token_service.proto" (package "token", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TokenDetail } from "./token_entity";
import { TokenOperation } from "./token_entity";
import { ResponseStatus } from "../base";
import { Timestamp } from "../google/protobuf/timestamp";
import { TokenType } from "./token_entity";
/**
 * @generated from protobuf message token.AddTokenReq
 */
export interface AddTokenReq {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: int32 token = 2;
     */
    token: number;
    /**
     * @generated from protobuf field: token.TokenType token_type = 3;
     */
    tokenType: TokenType;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp effective_at = 4;
     */
    effectiveAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expired_at = 5;
     */
    expiredAt?: Timestamp;
    /**
     * @generated from protobuf field: string biz_no = 6;
     */
    bizNo: string;
}
/**
 * @generated from protobuf message token.AddTokenResp
 */
export interface AddTokenResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message token.FreezeTokenReq
 */
export interface FreezeTokenReq {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: int32 token = 2;
     */
    token: number;
    /**
     * @generated from protobuf field: int32 token_type = 3;
     */
    tokenType: number;
    /**
     * @generated from protobuf field: string biz_no = 4;
     */
    bizNo: string;
}
/**
 * @generated from protobuf message token.FreezeTokenResp
 */
export interface FreezeTokenResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: token.FreezeTokenRespData data = 2;
     */
    data?: FreezeTokenRespData;
}
/**
 * @generated from protobuf message token.FreezeTokenRespData
 */
export interface FreezeTokenRespData {
    /**
     * @generated from protobuf field: int32 token_op_id = 1;
     */
    tokenOpId: number;
}
/**
 * @generated from protobuf message token.ConsumeTokenReq
 */
export interface ConsumeTokenReq {
    /**
     * @generated from protobuf field: int32 token_op_id = 1;
     */
    tokenOpId: number;
}
/**
 * @generated from protobuf message token.ConsumeTokenResp
 */
export interface ConsumeTokenResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message token.UnfreezeTokenReq
 */
export interface UnfreezeTokenReq {
    /**
     * @generated from protobuf field: int32 token_op_id = 1;
     */
    tokenOpId: number;
}
/**
 * @generated from protobuf message token.UnfreezeTokenResp
 */
export interface UnfreezeTokenResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
/**
 * @generated from protobuf message token.GetUserTokenOperationReq
 */
export interface GetUserTokenOperationReq {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
}
/**
 * @generated from protobuf message token.GetUserTokenOperationResp
 */
export interface GetUserTokenOperationResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: token.GetUserTokenOperationRespData data = 2;
     */
    data?: GetUserTokenOperationRespData;
}
/**
 * @generated from protobuf message token.GetUserTokenOperationRespData
 */
export interface GetUserTokenOperationRespData {
    /**
     * @generated from protobuf field: repeated token.TokenOperation token_ops = 1;
     */
    tokenOps: TokenOperation[];
}
/**
 * @generated from protobuf message token.GetUserTokenDetailReq
 */
export interface GetUserTokenDetailReq {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
}
/**
 * @generated from protobuf message token.GetUserTokenDetailResp
 */
export interface GetUserTokenDetailResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: token.GetUserTokenDetailRespData data = 2;
     */
    data?: GetUserTokenDetailRespData;
}
/**
 * @generated from protobuf message token.GetUserTokenDetailRespData
 */
export interface GetUserTokenDetailRespData {
    /**
     * @generated from protobuf field: repeated token.TokenDetail token_details = 1;
     */
    tokenDetails: TokenDetail[];
}
/**
 * @generated from protobuf message token.GetUserTokenTotalReq
 */
export interface GetUserTokenTotalReq {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: int32 token_type = 2;
     */
    tokenType: number;
}
/**
 * @generated from protobuf message token.GetUserTokenTotalResp
 */
export interface GetUserTokenTotalResp {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: token.GetUserTokenTotalRespData data = 2;
     */
    data?: GetUserTokenTotalRespData;
}
/**
 * @generated from protobuf message token.GetUserTokenTotalRespData
 */
export interface GetUserTokenTotalRespData {
    /**
     * @generated from protobuf field: int32 total = 1;
     */
    total: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class AddTokenReq$Type extends MessageType<AddTokenReq> {
    constructor() {
        super("token.AddTokenReq", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "token_type", kind: "enum", T: () => ["token.TokenType", TokenType] },
            { no: 4, name: "effective_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "expired_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "biz_no", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddTokenReq>): AddTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = "";
        message.token = 0;
        message.tokenType = 0;
        message.bizNo = "";
        if (value !== undefined)
            reflectionMergePartial<AddTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddTokenReq): AddTokenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* int32 token */ 2:
                    message.token = reader.int32();
                    break;
                case /* token.TokenType token_type */ 3:
                    message.tokenType = reader.int32();
                    break;
                case /* google.protobuf.Timestamp effective_at */ 4:
                    message.effectiveAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.effectiveAt);
                    break;
                case /* google.protobuf.Timestamp expired_at */ 5:
                    message.expiredAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiredAt);
                    break;
                case /* string biz_no */ 6:
                    message.bizNo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* int32 token = 2; */
        if (message.token !== 0)
            writer.tag(2, WireType.Varint).int32(message.token);
        /* token.TokenType token_type = 3; */
        if (message.tokenType !== 0)
            writer.tag(3, WireType.Varint).int32(message.tokenType);
        /* google.protobuf.Timestamp effective_at = 4; */
        if (message.effectiveAt)
            Timestamp.internalBinaryWrite(message.effectiveAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp expired_at = 5; */
        if (message.expiredAt)
            Timestamp.internalBinaryWrite(message.expiredAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string biz_no = 6; */
        if (message.bizNo !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.bizNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.AddTokenReq
 */
export const AddTokenReq = new AddTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTokenResp$Type extends MessageType<AddTokenResp> {
    constructor() {
        super("token.AddTokenResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<AddTokenResp>): AddTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AddTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddTokenResp): AddTokenResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.AddTokenResp
 */
export const AddTokenResp = new AddTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreezeTokenReq$Type extends MessageType<FreezeTokenReq> {
    constructor() {
        super("token.FreezeTokenReq", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "token_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "biz_no", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FreezeTokenReq>): FreezeTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = "";
        message.token = 0;
        message.tokenType = 0;
        message.bizNo = "";
        if (value !== undefined)
            reflectionMergePartial<FreezeTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreezeTokenReq): FreezeTokenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* int32 token */ 2:
                    message.token = reader.int32();
                    break;
                case /* int32 token_type */ 3:
                    message.tokenType = reader.int32();
                    break;
                case /* string biz_no */ 4:
                    message.bizNo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreezeTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* int32 token = 2; */
        if (message.token !== 0)
            writer.tag(2, WireType.Varint).int32(message.token);
        /* int32 token_type = 3; */
        if (message.tokenType !== 0)
            writer.tag(3, WireType.Varint).int32(message.tokenType);
        /* string biz_no = 4; */
        if (message.bizNo !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.bizNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.FreezeTokenReq
 */
export const FreezeTokenReq = new FreezeTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreezeTokenResp$Type extends MessageType<FreezeTokenResp> {
    constructor() {
        super("token.FreezeTokenResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => FreezeTokenRespData }
        ]);
    }
    create(value?: PartialMessage<FreezeTokenResp>): FreezeTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<FreezeTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreezeTokenResp): FreezeTokenResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* token.FreezeTokenRespData data */ 2:
                    message.data = FreezeTokenRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreezeTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* token.FreezeTokenRespData data = 2; */
        if (message.data)
            FreezeTokenRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.FreezeTokenResp
 */
export const FreezeTokenResp = new FreezeTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreezeTokenRespData$Type extends MessageType<FreezeTokenRespData> {
    constructor() {
        super("token.FreezeTokenRespData", [
            { no: 1, name: "token_op_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<FreezeTokenRespData>): FreezeTokenRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenOpId = 0;
        if (value !== undefined)
            reflectionMergePartial<FreezeTokenRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreezeTokenRespData): FreezeTokenRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 token_op_id */ 1:
                    message.tokenOpId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreezeTokenRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 token_op_id = 1; */
        if (message.tokenOpId !== 0)
            writer.tag(1, WireType.Varint).int32(message.tokenOpId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.FreezeTokenRespData
 */
export const FreezeTokenRespData = new FreezeTokenRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsumeTokenReq$Type extends MessageType<ConsumeTokenReq> {
    constructor() {
        super("token.ConsumeTokenReq", [
            { no: 1, name: "token_op_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ConsumeTokenReq>): ConsumeTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenOpId = 0;
        if (value !== undefined)
            reflectionMergePartial<ConsumeTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsumeTokenReq): ConsumeTokenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 token_op_id */ 1:
                    message.tokenOpId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsumeTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 token_op_id = 1; */
        if (message.tokenOpId !== 0)
            writer.tag(1, WireType.Varint).int32(message.tokenOpId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.ConsumeTokenReq
 */
export const ConsumeTokenReq = new ConsumeTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsumeTokenResp$Type extends MessageType<ConsumeTokenResp> {
    constructor() {
        super("token.ConsumeTokenResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<ConsumeTokenResp>): ConsumeTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ConsumeTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsumeTokenResp): ConsumeTokenResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsumeTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.ConsumeTokenResp
 */
export const ConsumeTokenResp = new ConsumeTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnfreezeTokenReq$Type extends MessageType<UnfreezeTokenReq> {
    constructor() {
        super("token.UnfreezeTokenReq", [
            { no: 1, name: "token_op_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UnfreezeTokenReq>): UnfreezeTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenOpId = 0;
        if (value !== undefined)
            reflectionMergePartial<UnfreezeTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnfreezeTokenReq): UnfreezeTokenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 token_op_id */ 1:
                    message.tokenOpId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnfreezeTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 token_op_id = 1; */
        if (message.tokenOpId !== 0)
            writer.tag(1, WireType.Varint).int32(message.tokenOpId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.UnfreezeTokenReq
 */
export const UnfreezeTokenReq = new UnfreezeTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnfreezeTokenResp$Type extends MessageType<UnfreezeTokenResp> {
    constructor() {
        super("token.UnfreezeTokenResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<UnfreezeTokenResp>): UnfreezeTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UnfreezeTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnfreezeTokenResp): UnfreezeTokenResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnfreezeTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.UnfreezeTokenResp
 */
export const UnfreezeTokenResp = new UnfreezeTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenOperationReq$Type extends MessageType<GetUserTokenOperationReq> {
    constructor() {
        super("token.GetUserTokenOperationReq", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenOperationReq>): GetUserTokenOperationReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenOperationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenOperationReq): GetUserTokenOperationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenOperationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenOperationReq
 */
export const GetUserTokenOperationReq = new GetUserTokenOperationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenOperationResp$Type extends MessageType<GetUserTokenOperationResp> {
    constructor() {
        super("token.GetUserTokenOperationResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetUserTokenOperationRespData }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenOperationResp>): GetUserTokenOperationResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenOperationResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenOperationResp): GetUserTokenOperationResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* token.GetUserTokenOperationRespData data */ 2:
                    message.data = GetUserTokenOperationRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenOperationResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* token.GetUserTokenOperationRespData data = 2; */
        if (message.data)
            GetUserTokenOperationRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenOperationResp
 */
export const GetUserTokenOperationResp = new GetUserTokenOperationResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenOperationRespData$Type extends MessageType<GetUserTokenOperationRespData> {
    constructor() {
        super("token.GetUserTokenOperationRespData", [
            { no: 1, name: "token_ops", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TokenOperation }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenOperationRespData>): GetUserTokenOperationRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenOps = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenOperationRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenOperationRespData): GetUserTokenOperationRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated token.TokenOperation token_ops */ 1:
                    message.tokenOps.push(TokenOperation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenOperationRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated token.TokenOperation token_ops = 1; */
        for (let i = 0; i < message.tokenOps.length; i++)
            TokenOperation.internalBinaryWrite(message.tokenOps[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenOperationRespData
 */
export const GetUserTokenOperationRespData = new GetUserTokenOperationRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenDetailReq$Type extends MessageType<GetUserTokenDetailReq> {
    constructor() {
        super("token.GetUserTokenDetailReq", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenDetailReq>): GetUserTokenDetailReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenDetailReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenDetailReq): GetUserTokenDetailReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenDetailReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenDetailReq
 */
export const GetUserTokenDetailReq = new GetUserTokenDetailReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenDetailResp$Type extends MessageType<GetUserTokenDetailResp> {
    constructor() {
        super("token.GetUserTokenDetailResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetUserTokenDetailRespData }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenDetailResp>): GetUserTokenDetailResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenDetailResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenDetailResp): GetUserTokenDetailResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* token.GetUserTokenDetailRespData data */ 2:
                    message.data = GetUserTokenDetailRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenDetailResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* token.GetUserTokenDetailRespData data = 2; */
        if (message.data)
            GetUserTokenDetailRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenDetailResp
 */
export const GetUserTokenDetailResp = new GetUserTokenDetailResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenDetailRespData$Type extends MessageType<GetUserTokenDetailRespData> {
    constructor() {
        super("token.GetUserTokenDetailRespData", [
            { no: 1, name: "token_details", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TokenDetail }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenDetailRespData>): GetUserTokenDetailRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenDetails = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenDetailRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenDetailRespData): GetUserTokenDetailRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated token.TokenDetail token_details */ 1:
                    message.tokenDetails.push(TokenDetail.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenDetailRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated token.TokenDetail token_details = 1; */
        for (let i = 0; i < message.tokenDetails.length; i++)
            TokenDetail.internalBinaryWrite(message.tokenDetails[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenDetailRespData
 */
export const GetUserTokenDetailRespData = new GetUserTokenDetailRespData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenTotalReq$Type extends MessageType<GetUserTokenTotalReq> {
    constructor() {
        super("token.GetUserTokenTotalReq", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenTotalReq>): GetUserTokenTotalReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = "";
        message.tokenType = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenTotalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenTotalReq): GetUserTokenTotalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* int32 token_type */ 2:
                    message.tokenType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenTotalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* int32 token_type = 2; */
        if (message.tokenType !== 0)
            writer.tag(2, WireType.Varint).int32(message.tokenType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenTotalReq
 */
export const GetUserTokenTotalReq = new GetUserTokenTotalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenTotalResp$Type extends MessageType<GetUserTokenTotalResp> {
    constructor() {
        super("token.GetUserTokenTotalResp", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "data", kind: "message", T: () => GetUserTokenTotalRespData }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenTotalResp>): GetUserTokenTotalResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenTotalResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenTotalResp): GetUserTokenTotalResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* token.GetUserTokenTotalRespData data */ 2:
                    message.data = GetUserTokenTotalRespData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenTotalResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* token.GetUserTokenTotalRespData data = 2; */
        if (message.data)
            GetUserTokenTotalRespData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenTotalResp
 */
export const GetUserTokenTotalResp = new GetUserTokenTotalResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserTokenTotalRespData$Type extends MessageType<GetUserTokenTotalRespData> {
    constructor() {
        super("token.GetUserTokenTotalRespData", [
            { no: 1, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserTokenTotalRespData>): GetUserTokenTotalRespData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserTokenTotalRespData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserTokenTotalRespData): GetUserTokenTotalRespData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 total */ 1:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserTokenTotalRespData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.GetUserTokenTotalRespData
 */
export const GetUserTokenTotalRespData = new GetUserTokenTotalRespData$Type();
/**
 * @generated ServiceType for protobuf service token.TokenService
 */
export const TokenService = new ServiceType("token.TokenService", [
    { name: "AddToken", options: { "api.post": "/token/add" }, I: AddTokenReq, O: AddTokenResp },
    { name: "FreezeToken", options: { "api.post": "/token/freeze" }, I: FreezeTokenReq, O: FreezeTokenResp },
    { name: "ConsumeToken", options: { "api.post": "/token/consume" }, I: ConsumeTokenReq, O: ConsumeTokenResp },
    { name: "UnfreezeToken", options: { "api.post": "/token/unfreeze" }, I: UnfreezeTokenReq, O: UnfreezeTokenResp },
    { name: "GetUserTokenOperation", options: { "api.post": "/token/get-operations" }, I: GetUserTokenOperationReq, O: GetUserTokenOperationResp },
    { name: "GetUserTokenDetail", options: { "api.post": "/token/get-details" }, I: GetUserTokenDetailReq, O: GetUserTokenOperationResp },
    { name: "GetUserTokenTotal", options: { "api.post": "/token/get-total" }, I: GetUserTokenTotalReq, O: GetUserTokenTotalResp }
]);
