// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as user_message_service from './user_message_service'

export class MsgServiceRealClient {
  constructor(private transport: Transport) {}

  async AddUserMessage(
    request: user_message_service.UserMessage,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<user_message_service.AddUserMessageResp> {
    return this.transport.request({
      path: '/user-message/add',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goMsgService = new MsgServiceRealClient(productionClient)
