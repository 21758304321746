// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/comment_service.proto" (package "comment", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { CommentService } from "./comment_service";
import type { UpdateCommentPreferenceResp } from "./comment_service";
import type { UpdateCommentPreferenceReq } from "./comment_service";
import type { GetWebsiteCommentReplyListResp } from "./comment_service";
import type { GetWebsiteCommentReplyListReq } from "./comment_service";
import type { GetWebsiteCommentListResp } from "./comment_service";
import type { GetWebsiteCommentListReq } from "./comment_service";
import type { DeleteCommentResp } from "./comment_service";
import type { DeleteCommentReq } from "./comment_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AddCommentResp } from "./comment_service";
import type { AddCommentReq } from "./comment_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service comment.CommentService
 */
export interface ICommentServiceClient {
    /**
     * 新增评论
     *
     * @generated from protobuf rpc: AddComment(comment.AddCommentReq) returns (comment.AddCommentResp);
     */
    addComment(input: AddCommentReq, options?: RpcOptions): UnaryCall<AddCommentReq, AddCommentResp>;
    /**
     * 删除评论
     *
     * @generated from protobuf rpc: DeleteComment(comment.DeleteCommentReq) returns (comment.DeleteCommentResp);
     */
    deleteComment(input: DeleteCommentReq, options?: RpcOptions): UnaryCall<DeleteCommentReq, DeleteCommentResp>;
    /**
     * 获取评论列表
     *
     * @generated from protobuf rpc: GetWebsiteCommentList(comment.GetWebsiteCommentListReq) returns (comment.GetWebsiteCommentListResp);
     */
    getWebsiteCommentList(input: GetWebsiteCommentListReq, options?: RpcOptions): UnaryCall<GetWebsiteCommentListReq, GetWebsiteCommentListResp>;
    /**
     * 获取评论回复列表
     *
     * @generated from protobuf rpc: GetWebsiteCommentReplyList(comment.GetWebsiteCommentReplyListReq) returns (comment.GetWebsiteCommentReplyListResp);
     */
    getWebsiteCommentReplyList(input: GetWebsiteCommentReplyListReq, options?: RpcOptions): UnaryCall<GetWebsiteCommentReplyListReq, GetWebsiteCommentReplyListResp>;
    /**
     * 评论点赞/点踩
     *
     * @generated from protobuf rpc: UpdateCommentPreference(comment.UpdateCommentPreferenceReq) returns (comment.UpdateCommentPreferenceResp);
     */
    updateCommentPreference(input: UpdateCommentPreferenceReq, options?: RpcOptions): UnaryCall<UpdateCommentPreferenceReq, UpdateCommentPreferenceResp>;
}
/**
 * @generated from protobuf service comment.CommentService
 */
export class CommentServiceClient implements ICommentServiceClient, ServiceInfo {
    typeName = CommentService.typeName;
    methods = CommentService.methods;
    options = CommentService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * 新增评论
     *
     * @generated from protobuf rpc: AddComment(comment.AddCommentReq) returns (comment.AddCommentResp);
     */
    addComment(input: AddCommentReq, options?: RpcOptions): UnaryCall<AddCommentReq, AddCommentResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddCommentReq, AddCommentResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除评论
     *
     * @generated from protobuf rpc: DeleteComment(comment.DeleteCommentReq) returns (comment.DeleteCommentResp);
     */
    deleteComment(input: DeleteCommentReq, options?: RpcOptions): UnaryCall<DeleteCommentReq, DeleteCommentResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteCommentReq, DeleteCommentResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取评论列表
     *
     * @generated from protobuf rpc: GetWebsiteCommentList(comment.GetWebsiteCommentListReq) returns (comment.GetWebsiteCommentListResp);
     */
    getWebsiteCommentList(input: GetWebsiteCommentListReq, options?: RpcOptions): UnaryCall<GetWebsiteCommentListReq, GetWebsiteCommentListResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteCommentListReq, GetWebsiteCommentListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取评论回复列表
     *
     * @generated from protobuf rpc: GetWebsiteCommentReplyList(comment.GetWebsiteCommentReplyListReq) returns (comment.GetWebsiteCommentReplyListResp);
     */
    getWebsiteCommentReplyList(input: GetWebsiteCommentReplyListReq, options?: RpcOptions): UnaryCall<GetWebsiteCommentReplyListReq, GetWebsiteCommentReplyListResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteCommentReplyListReq, GetWebsiteCommentReplyListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 评论点赞/点踩
     *
     * @generated from protobuf rpc: UpdateCommentPreference(comment.UpdateCommentPreferenceReq) returns (comment.UpdateCommentPreferenceResp);
     */
    updateCommentPreference(input: UpdateCommentPreferenceReq, options?: RpcOptions): UnaryCall<UpdateCommentPreferenceReq, UpdateCommentPreferenceResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCommentPreferenceReq, UpdateCommentPreferenceResp>("unary", this._transport, method, opt, input);
    }
}
