// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "user_message/user_message_api.proto" (package "user_message", syntax proto3)
// tslint:disable
import { DeleteUserMessageResp } from "./user_message_service";
import { DeleteUserMessageReq } from "./user_message_service";
import { ReadUserMessageResp } from "./user_message_service";
import { ReadUserMessageReq } from "./user_message_service";
import { GetMsgListReq } from "./user_message_service";
import { GetMsgListResp } from "./user_message_service";
import { Empty } from "../google/protobuf/empty";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated ServiceType for protobuf service user_message.MsgApiService
 */
export const MsgApiService = new ServiceType("user_message.MsgApiService", [
    { name: "GetUserAllMessageList", options: { "api.post": "/api/user-message/all" }, I: Empty, O: GetMsgListResp },
    { name: "GetUserMessageList", options: { "api.post": "/api/user-message/page" }, I: GetMsgListReq, O: GetMsgListResp },
    { name: "ReadUserMessage", options: { "api.post": "/api/user-message/read" }, I: ReadUserMessageReq, O: ReadUserMessageResp },
    { name: "DeleteUserMessage", options: { "api.post": "/api/user-message/delete" }, I: DeleteUserMessageReq, O: DeleteUserMessageResp }
]);
