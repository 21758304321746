// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "base.proto" (package "base", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message base.ResponseStatus
 */
export interface ResponseStatus {
    /**
     * @generated from protobuf field: int32 code = 1;
     */
    code: number;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * @generated from protobuf field: map<string, string> extension_kv = 3;
     */
    extensionKv: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message base.Page
 */
export interface Page {
    /**
     * @generated from protobuf field: int32 page_no = 1;
     */
    pageNo: number;
    /**
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ResponseStatus$Type extends MessageType<ResponseStatus> {
    constructor() {
        super("base.ResponseStatus", [
            { no: 1, name: "code", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "api.go_tag": "json:\"code\"" } },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "api.go_tag": "json:\"message\"" } },
            { no: 3, name: "extension_kv", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ }, options: { "api.go_tag": "json:\"extension_kv\"" } }
        ]);
    }
    create(value?: PartialMessage<ResponseStatus>): ResponseStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.message = "";
        message.extensionKv = {};
        if (value !== undefined)
            reflectionMergePartial<ResponseStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResponseStatus): ResponseStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 code */ 1:
                    message.code = reader.int32();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                case /* map<string, string> extension_kv */ 3:
                    this.binaryReadMap3(message.extensionKv, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: ResponseStatus["extensionKv"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ResponseStatus["extensionKv"] | undefined, val: ResponseStatus["extensionKv"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field base.ResponseStatus.extension_kv");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: ResponseStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* map<string, string> extension_kv = 3; */
        for (let k of globalThis.Object.keys(message.extensionKv))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.extensionKv[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message base.ResponseStatus
 */
export const ResponseStatus = new ResponseStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Page$Type extends MessageType<Page> {
    constructor() {
        super("base.Page", [
            { no: 1, name: "page_no", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Page>): Page {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pageNo = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<Page>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Page): Page {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_no */ 1:
                    message.pageNo = reader.int32();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Page, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_no = 1; */
        if (message.pageNo !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageNo);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message base.Page
 */
export const Page = new Page$Type();
