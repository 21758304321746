// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as order_service from './order_service'

export class OrderServiceRealClient {
  constructor(private transport: Transport) {}

  async CreateOrder(
    request: order_service.CreateOrderRequest,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<order_service.CreateOrderResponse> {
    return this.transport.request({
      path: '/api/order/create',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetOrder(
    request: order_service.GetOrderRequest,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<order_service.GetOrderResponse> {
    return this.transport.request({
      path: '/api/order/get',
      method: 'GET',
      input: request,
      options
    })
  }


  async CancelOrder(
    request: order_service.CancelOrderRequest,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<order_service.CancelOrderResponse> {
    return this.transport.request({
      path: '/api/order/cancel',
      method: 'DELETE',
      input: request,
      options
    })
  }
}

export const goOrderService = new OrderServiceRealClient(productionClient)
