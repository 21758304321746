// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "order/order_service.proto" (package "order_service", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TradeOrder } from "./order_entities";
import { ResponseStatus } from "../base";
/**
 * @generated from protobuf message order_service.CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: string product_id = 2;
     */
    productId: string;
}
/**
 * @generated from protobuf message order_service.CreateOrderResponse
 */
export interface CreateOrderResponse {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: order_entities.TradeOrder order = 2;
     */
    order?: TradeOrder;
}
/**
 * @generated from protobuf message order_service.GetOrderRequest
 */
export interface GetOrderRequest {
    /**
     * @generated from protobuf field: string order_id = 1;
     */
    orderId: string;
}
/**
 * @generated from protobuf message order_service.GetOrderResponse
 */
export interface GetOrderResponse {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
    /**
     * @generated from protobuf field: order_entities.TradeOrder order = 2;
     */
    order?: TradeOrder;
}
/**
 * @generated from protobuf message order_service.CancelOrderRequest
 */
export interface CancelOrderRequest {
    /**
     * @generated from protobuf field: string order_id = 1;
     */
    orderId: string;
    /**
     * @generated from protobuf field: string reason = 2;
     */
    reason: string;
}
/**
 * @generated from protobuf message order_service.CancelOrderResponse
 */
export interface CancelOrderResponse {
    /**
     * @generated from protobuf field: base.ResponseStatus status = 1;
     */
    status?: ResponseStatus;
}
// @generated message type with reflection information, may provide speed optimized methods
class CreateOrderRequest$Type extends MessageType<CreateOrderRequest> {
    constructor() {
        super("order_service.CreateOrderRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "product_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateOrderRequest>): CreateOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = "";
        message.productId = "";
        if (value !== undefined)
            reflectionMergePartial<CreateOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOrderRequest): CreateOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* string product_id */ 2:
                    message.productId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* string product_id = 2; */
        if (message.productId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.productId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_service.CreateOrderRequest
 */
export const CreateOrderRequest = new CreateOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateOrderResponse$Type extends MessageType<CreateOrderResponse> {
    constructor() {
        super("order_service.CreateOrderResponse", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "order", kind: "message", T: () => TradeOrder }
        ]);
    }
    create(value?: PartialMessage<CreateOrderResponse>): CreateOrderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateOrderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOrderResponse): CreateOrderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* order_entities.TradeOrder order */ 2:
                    message.order = TradeOrder.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOrderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* order_entities.TradeOrder order = 2; */
        if (message.order)
            TradeOrder.internalBinaryWrite(message.order, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_service.CreateOrderResponse
 */
export const CreateOrderResponse = new CreateOrderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrderRequest$Type extends MessageType<GetOrderRequest> {
    constructor() {
        super("order_service.GetOrderRequest", [
            { no: 1, name: "order_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOrderRequest>): GetOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        if (value !== undefined)
            reflectionMergePartial<GetOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrderRequest): GetOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string order_id */ 1:
                    message.orderId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string order_id = 1; */
        if (message.orderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.orderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_service.GetOrderRequest
 */
export const GetOrderRequest = new GetOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrderResponse$Type extends MessageType<GetOrderResponse> {
    constructor() {
        super("order_service.GetOrderResponse", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus },
            { no: 2, name: "order", kind: "message", T: () => TradeOrder }
        ]);
    }
    create(value?: PartialMessage<GetOrderResponse>): GetOrderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetOrderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrderResponse): GetOrderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* order_entities.TradeOrder order */ 2:
                    message.order = TradeOrder.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* order_entities.TradeOrder order = 2; */
        if (message.order)
            TradeOrder.internalBinaryWrite(message.order, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_service.GetOrderResponse
 */
export const GetOrderResponse = new GetOrderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelOrderRequest$Type extends MessageType<CancelOrderRequest> {
    constructor() {
        super("order_service.CancelOrderRequest", [
            { no: 1, name: "order_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelOrderRequest>): CancelOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        message.reason = "";
        if (value !== undefined)
            reflectionMergePartial<CancelOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelOrderRequest): CancelOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string order_id */ 1:
                    message.orderId = reader.string();
                    break;
                case /* string reason */ 2:
                    message.reason = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string order_id = 1; */
        if (message.orderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.orderId);
        /* string reason = 2; */
        if (message.reason !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_service.CancelOrderRequest
 */
export const CancelOrderRequest = new CancelOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelOrderResponse$Type extends MessageType<CancelOrderResponse> {
    constructor() {
        super("order_service.CancelOrderResponse", [
            { no: 1, name: "status", kind: "message", T: () => ResponseStatus }
        ]);
    }
    create(value?: PartialMessage<CancelOrderResponse>): CancelOrderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CancelOrderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelOrderResponse): CancelOrderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* base.ResponseStatus status */ 1:
                    message.status = ResponseStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelOrderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* base.ResponseStatus status = 1; */
        if (message.status)
            ResponseStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_service.CancelOrderResponse
 */
export const CancelOrderResponse = new CancelOrderResponse$Type();
/**
 * @generated ServiceType for protobuf service order_service.OrderService
 */
export const OrderService = new ServiceType("order_service.OrderService", [
    { name: "CreateOrder", options: { "api.post": "/api/order/create" }, I: CreateOrderRequest, O: CreateOrderResponse },
    { name: "GetOrder", options: { "api.get": "/api/order/get" }, I: GetOrderRequest, O: GetOrderResponse },
    { name: "CancelOrder", options: { "api.delete": "/api/order/cancel" }, I: CancelOrderRequest, O: CancelOrderResponse }
]);
