// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "token/token_service.proto" (package "token", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { TokenService } from "./token_service";
import type { GetUserTokenTotalResp } from "./token_service";
import type { GetUserTokenTotalReq } from "./token_service";
import type { GetUserTokenDetailReq } from "./token_service";
import type { GetUserTokenOperationResp } from "./token_service";
import type { GetUserTokenOperationReq } from "./token_service";
import type { UnfreezeTokenResp } from "./token_service";
import type { UnfreezeTokenReq } from "./token_service";
import type { ConsumeTokenResp } from "./token_service";
import type { ConsumeTokenReq } from "./token_service";
import type { FreezeTokenResp } from "./token_service";
import type { FreezeTokenReq } from "./token_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AddTokenResp } from "./token_service";
import type { AddTokenReq } from "./token_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service token.TokenService
 */
export interface ITokenServiceClient {
    /**
     * @generated from protobuf rpc: AddToken(token.AddTokenReq) returns (token.AddTokenResp);
     */
    addToken(input: AddTokenReq, options?: RpcOptions): UnaryCall<AddTokenReq, AddTokenResp>;
    /**
     * @generated from protobuf rpc: FreezeToken(token.FreezeTokenReq) returns (token.FreezeTokenResp);
     */
    freezeToken(input: FreezeTokenReq, options?: RpcOptions): UnaryCall<FreezeTokenReq, FreezeTokenResp>;
    /**
     * @generated from protobuf rpc: ConsumeToken(token.ConsumeTokenReq) returns (token.ConsumeTokenResp);
     */
    consumeToken(input: ConsumeTokenReq, options?: RpcOptions): UnaryCall<ConsumeTokenReq, ConsumeTokenResp>;
    /**
     * @generated from protobuf rpc: UnfreezeToken(token.UnfreezeTokenReq) returns (token.UnfreezeTokenResp);
     */
    unfreezeToken(input: UnfreezeTokenReq, options?: RpcOptions): UnaryCall<UnfreezeTokenReq, UnfreezeTokenResp>;
    /**
     * @generated from protobuf rpc: GetUserTokenOperation(token.GetUserTokenOperationReq) returns (token.GetUserTokenOperationResp);
     */
    getUserTokenOperation(input: GetUserTokenOperationReq, options?: RpcOptions): UnaryCall<GetUserTokenOperationReq, GetUserTokenOperationResp>;
    /**
     * @generated from protobuf rpc: GetUserTokenDetail(token.GetUserTokenDetailReq) returns (token.GetUserTokenOperationResp);
     */
    getUserTokenDetail(input: GetUserTokenDetailReq, options?: RpcOptions): UnaryCall<GetUserTokenDetailReq, GetUserTokenOperationResp>;
    /**
     * @generated from protobuf rpc: GetUserTokenTotal(token.GetUserTokenTotalReq) returns (token.GetUserTokenTotalResp);
     */
    getUserTokenTotal(input: GetUserTokenTotalReq, options?: RpcOptions): UnaryCall<GetUserTokenTotalReq, GetUserTokenTotalResp>;
}
/**
 * @generated from protobuf service token.TokenService
 */
export class TokenServiceClient implements ITokenServiceClient, ServiceInfo {
    typeName = TokenService.typeName;
    methods = TokenService.methods;
    options = TokenService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AddToken(token.AddTokenReq) returns (token.AddTokenResp);
     */
    addToken(input: AddTokenReq, options?: RpcOptions): UnaryCall<AddTokenReq, AddTokenResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddTokenReq, AddTokenResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FreezeToken(token.FreezeTokenReq) returns (token.FreezeTokenResp);
     */
    freezeToken(input: FreezeTokenReq, options?: RpcOptions): UnaryCall<FreezeTokenReq, FreezeTokenResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<FreezeTokenReq, FreezeTokenResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ConsumeToken(token.ConsumeTokenReq) returns (token.ConsumeTokenResp);
     */
    consumeToken(input: ConsumeTokenReq, options?: RpcOptions): UnaryCall<ConsumeTokenReq, ConsumeTokenResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConsumeTokenReq, ConsumeTokenResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UnfreezeToken(token.UnfreezeTokenReq) returns (token.UnfreezeTokenResp);
     */
    unfreezeToken(input: UnfreezeTokenReq, options?: RpcOptions): UnaryCall<UnfreezeTokenReq, UnfreezeTokenResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UnfreezeTokenReq, UnfreezeTokenResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserTokenOperation(token.GetUserTokenOperationReq) returns (token.GetUserTokenOperationResp);
     */
    getUserTokenOperation(input: GetUserTokenOperationReq, options?: RpcOptions): UnaryCall<GetUserTokenOperationReq, GetUserTokenOperationResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserTokenOperationReq, GetUserTokenOperationResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserTokenDetail(token.GetUserTokenDetailReq) returns (token.GetUserTokenOperationResp);
     */
    getUserTokenDetail(input: GetUserTokenDetailReq, options?: RpcOptions): UnaryCall<GetUserTokenDetailReq, GetUserTokenOperationResp> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserTokenDetailReq, GetUserTokenOperationResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserTokenTotal(token.GetUserTokenTotalReq) returns (token.GetUserTokenTotalResp);
     */
    getUserTokenTotal(input: GetUserTokenTotalReq, options?: RpcOptions): UnaryCall<GetUserTokenTotalReq, GetUserTokenTotalResp> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserTokenTotalReq, GetUserTokenTotalResp>("unary", this._transport, method, opt, input);
    }
}
