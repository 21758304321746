// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/dto.proto" (package "dto", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message dto.Website
 */
export interface Website {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string current_version_id = 3;
     */
    currentVersionId: string;
    /**
     * @generated from protobuf field: optional string description = 4;
     */
    description?: string;
    /**
     * @generated from protobuf field: repeated string tags = 5;
     */
    tags: string[];
    /**
     * @generated from protobuf field: int32 likes = 6;
     */
    likes: number;
    /**
     * @generated from protobuf field: int32 views = 7;
     */
    views: number;
    /**
     * @generated from protobuf field: dto.UserInfo own_by_user_info = 8;
     */
    ownByUserInfo?: UserInfo;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 9;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 10;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: optional string url = 11;
     */
    url?: string;
    /**
     * @generated from protobuf field: optional bool is_published = 12;
     */
    isPublished?: boolean;
    /**
     * @generated from protobuf field: optional string published_version_id = 13;
     */
    publishedVersionId?: string;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp published_at = 14;
     */
    publishedAt?: Timestamp;
}
/**
 * @generated from protobuf message dto.UserInfo
 */
export interface UserInfo {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 2;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: string username = 3;
     */
    username: string;
    /**
     * @generated from protobuf field: string full_name = 4;
     */
    fullName: string;
    /**
     * @generated from protobuf field: string avatar_url = 5;
     */
    avatarUrl: string;
    /**
     * @generated from protobuf field: string website = 6;
     */
    website: string;
}
/**
 * @generated from protobuf message dto.UserMessage
 */
export interface UserMessage {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp create_time = 3;
     */
    createTime?: Timestamp;
    /**
     * @generated from protobuf field: bool is_read = 4;
     */
    isRead: boolean;
    /**
     * @generated from protobuf field: int32 type = 5;
     */
    type: number;
    /**
     * @generated from protobuf field: string message = 6;
     */
    message: string;
    /**
     * @generated from protobuf field: string website_id = 7;
     */
    websiteId: string;
}
/**
 * @generated from protobuf message dto.Comment
 */
export interface Comment {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string;
    /**
     * @generated from protobuf field: string website_id = 3;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: string website_version_id = 4;
     */
    websiteVersionId: string;
    /**
     * @generated from protobuf field: dto.UserInfo own_by_user_info = 5;
     */
    ownByUserInfo?: UserInfo;
    /**
     * @generated from protobuf field: bool is_author = 9;
     */
    isAuthor: boolean;
    /**
     * @generated from protobuf field: string parent_id = 6;
     */
    parentId: string;
    /**
     * @generated from protobuf field: string reply_comment_id = 7;
     */
    replyCommentId: string;
    /**
     * @generated from protobuf field: dto.UserInfo reply_to_user_info = 8;
     */
    replyToUserInfo?: UserInfo;
    /**
     * @generated from protobuf field: int32 likes = 10;
     */
    likes: number;
    /**
     * @generated from protobuf field: int32 dislikes = 11;
     */
    dislikes: number;
    /**
     * @generated from protobuf field: int32 children_count = 15;
     */
    childrenCount: number;
    /**
     * @generated from protobuf field: repeated dto.Comment children = 14;
     */
    children: Comment[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 12;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 13;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: bool is_like = 16;
     */
    isLike: boolean;
    /**
     * @generated from protobuf field: bool is_dislike = 17;
     */
    isDislike: boolean;
}
/**
 * @generated from protobuf message dto.WebsiteVersion
 */
export interface WebsiteVersion {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string prompt = 2;
     */
    prompt: string;
    /**
     * @generated from protobuf field: string html = 3;
     */
    html: string;
    /**
     * @generated from protobuf field: dto.UserInfo created_by_user_info = 4;
     */
    createdByUserInfo?: UserInfo;
    /**
     * @generated from protobuf field: string from_version_id = 5;
     */
    fromVersionId: string;
    /**
     * @generated from protobuf field: string website_id = 6;
     */
    websiteId: string;
    /**
     * @generated from protobuf field: string reason = 7;
     */
    reason: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp revoked_at = 8;
     */
    revokedAt?: Timestamp;
    /**
     * @generated from protobuf field: string prompt_image = 9;
     */
    promptImage: string;
    /**
     * @generated from protobuf field: string preview_image = 10;
     */
    previewImage: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 11;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 12;
     */
    updatedAt?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class Website$Type extends MessageType<Website> {
    constructor() {
        super("dto.Website", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "current_version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "likes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "views", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "own_by_user_info", kind: "message", T: () => UserInfo },
            { no: 9, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "url", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "is_published", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "published_version_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "published_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Website>): Website {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.title = "";
        message.currentVersionId = "";
        message.tags = [];
        message.likes = 0;
        message.views = 0;
        if (value !== undefined)
            reflectionMergePartial<Website>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Website): Website {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string current_version_id */ 3:
                    message.currentVersionId = reader.string();
                    break;
                case /* optional string description */ 4:
                    message.description = reader.string();
                    break;
                case /* repeated string tags */ 5:
                    message.tags.push(reader.string());
                    break;
                case /* int32 likes */ 6:
                    message.likes = reader.int32();
                    break;
                case /* int32 views */ 7:
                    message.views = reader.int32();
                    break;
                case /* dto.UserInfo own_by_user_info */ 8:
                    message.ownByUserInfo = UserInfo.internalBinaryRead(reader, reader.uint32(), options, message.ownByUserInfo);
                    break;
                case /* google.protobuf.Timestamp created_at */ 9:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 10:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* optional string url */ 11:
                    message.url = reader.string();
                    break;
                case /* optional bool is_published */ 12:
                    message.isPublished = reader.bool();
                    break;
                case /* optional string published_version_id */ 13:
                    message.publishedVersionId = reader.string();
                    break;
                case /* optional google.protobuf.Timestamp published_at */ 14:
                    message.publishedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.publishedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Website, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string current_version_id = 3; */
        if (message.currentVersionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.currentVersionId);
        /* optional string description = 4; */
        if (message.description !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* repeated string tags = 5; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.tags[i]);
        /* int32 likes = 6; */
        if (message.likes !== 0)
            writer.tag(6, WireType.Varint).int32(message.likes);
        /* int32 views = 7; */
        if (message.views !== 0)
            writer.tag(7, WireType.Varint).int32(message.views);
        /* dto.UserInfo own_by_user_info = 8; */
        if (message.ownByUserInfo)
            UserInfo.internalBinaryWrite(message.ownByUserInfo, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 9; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 10; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* optional string url = 11; */
        if (message.url !== undefined)
            writer.tag(11, WireType.LengthDelimited).string(message.url);
        /* optional bool is_published = 12; */
        if (message.isPublished !== undefined)
            writer.tag(12, WireType.Varint).bool(message.isPublished);
        /* optional string published_version_id = 13; */
        if (message.publishedVersionId !== undefined)
            writer.tag(13, WireType.LengthDelimited).string(message.publishedVersionId);
        /* optional google.protobuf.Timestamp published_at = 14; */
        if (message.publishedAt)
            Timestamp.internalBinaryWrite(message.publishedAt, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dto.Website
 */
export const Website = new Website$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfo$Type extends MessageType<UserInfo> {
    constructor() {
        super("dto.UserInfo", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "full_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "avatar_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "website", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserInfo>): UserInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.username = "";
        message.fullName = "";
        message.avatarUrl = "";
        message.website = "";
        if (value !== undefined)
            reflectionMergePartial<UserInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInfo): UserInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp updated_at */ 2:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* string username */ 3:
                    message.username = reader.string();
                    break;
                case /* string full_name */ 4:
                    message.fullName = reader.string();
                    break;
                case /* string avatar_url */ 5:
                    message.avatarUrl = reader.string();
                    break;
                case /* string website */ 6:
                    message.website = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp updated_at = 2; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string username = 3; */
        if (message.username !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.username);
        /* string full_name = 4; */
        if (message.fullName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.fullName);
        /* string avatar_url = 5; */
        if (message.avatarUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.avatarUrl);
        /* string website = 6; */
        if (message.website !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.website);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dto.UserInfo
 */
export const UserInfo = new UserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserMessage$Type extends MessageType<UserMessage> {
    constructor() {
        super("dto.UserMessage", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "create_time", kind: "message", T: () => Timestamp },
            { no: 4, name: "is_read", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserMessage>): UserMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.userId = "";
        message.isRead = false;
        message.type = 0;
        message.message = "";
        message.websiteId = "";
        if (value !== undefined)
            reflectionMergePartial<UserMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserMessage): UserMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* google.protobuf.Timestamp create_time */ 3:
                    message.createTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createTime);
                    break;
                case /* bool is_read */ 4:
                    message.isRead = reader.bool();
                    break;
                case /* int32 type */ 5:
                    message.type = reader.int32();
                    break;
                case /* string message */ 6:
                    message.message = reader.string();
                    break;
                case /* string website_id */ 7:
                    message.websiteId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* google.protobuf.Timestamp create_time = 3; */
        if (message.createTime)
            Timestamp.internalBinaryWrite(message.createTime, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool is_read = 4; */
        if (message.isRead !== false)
            writer.tag(4, WireType.Varint).bool(message.isRead);
        /* int32 type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* string message = 6; */
        if (message.message !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.message);
        /* string website_id = 7; */
        if (message.websiteId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.websiteId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dto.UserMessage
 */
export const UserMessage = new UserMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Comment$Type extends MessageType<Comment> {
    constructor() {
        super("dto.Comment", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "website_version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "own_by_user_info", kind: "message", T: () => UserInfo },
            { no: 9, name: "is_author", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "parent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "reply_comment_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "reply_to_user_info", kind: "message", T: () => UserInfo },
            { no: 10, name: "likes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "dislikes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "children_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "children", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Comment },
            { no: 12, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 13, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 16, name: "is_like", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "is_dislike", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Comment>): Comment {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.content = "";
        message.websiteId = "";
        message.websiteVersionId = "";
        message.isAuthor = false;
        message.parentId = "";
        message.replyCommentId = "";
        message.likes = 0;
        message.dislikes = 0;
        message.childrenCount = 0;
        message.children = [];
        message.isLike = false;
        message.isDislike = false;
        if (value !== undefined)
            reflectionMergePartial<Comment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Comment): Comment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                case /* string website_id */ 3:
                    message.websiteId = reader.string();
                    break;
                case /* string website_version_id */ 4:
                    message.websiteVersionId = reader.string();
                    break;
                case /* dto.UserInfo own_by_user_info */ 5:
                    message.ownByUserInfo = UserInfo.internalBinaryRead(reader, reader.uint32(), options, message.ownByUserInfo);
                    break;
                case /* bool is_author */ 9:
                    message.isAuthor = reader.bool();
                    break;
                case /* string parent_id */ 6:
                    message.parentId = reader.string();
                    break;
                case /* string reply_comment_id */ 7:
                    message.replyCommentId = reader.string();
                    break;
                case /* dto.UserInfo reply_to_user_info */ 8:
                    message.replyToUserInfo = UserInfo.internalBinaryRead(reader, reader.uint32(), options, message.replyToUserInfo);
                    break;
                case /* int32 likes */ 10:
                    message.likes = reader.int32();
                    break;
                case /* int32 dislikes */ 11:
                    message.dislikes = reader.int32();
                    break;
                case /* int32 children_count */ 15:
                    message.childrenCount = reader.int32();
                    break;
                case /* repeated dto.Comment children */ 14:
                    message.children.push(Comment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp created_at */ 12:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 13:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* bool is_like */ 16:
                    message.isLike = reader.bool();
                    break;
                case /* bool is_dislike */ 17:
                    message.isDislike = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Comment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        /* string website_id = 3; */
        if (message.websiteId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.websiteId);
        /* string website_version_id = 4; */
        if (message.websiteVersionId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.websiteVersionId);
        /* dto.UserInfo own_by_user_info = 5; */
        if (message.ownByUserInfo)
            UserInfo.internalBinaryWrite(message.ownByUserInfo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool is_author = 9; */
        if (message.isAuthor !== false)
            writer.tag(9, WireType.Varint).bool(message.isAuthor);
        /* string parent_id = 6; */
        if (message.parentId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.parentId);
        /* string reply_comment_id = 7; */
        if (message.replyCommentId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.replyCommentId);
        /* dto.UserInfo reply_to_user_info = 8; */
        if (message.replyToUserInfo)
            UserInfo.internalBinaryWrite(message.replyToUserInfo, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int32 likes = 10; */
        if (message.likes !== 0)
            writer.tag(10, WireType.Varint).int32(message.likes);
        /* int32 dislikes = 11; */
        if (message.dislikes !== 0)
            writer.tag(11, WireType.Varint).int32(message.dislikes);
        /* int32 children_count = 15; */
        if (message.childrenCount !== 0)
            writer.tag(15, WireType.Varint).int32(message.childrenCount);
        /* repeated dto.Comment children = 14; */
        for (let i = 0; i < message.children.length; i++)
            Comment.internalBinaryWrite(message.children[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 12; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 13; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bool is_like = 16; */
        if (message.isLike !== false)
            writer.tag(16, WireType.Varint).bool(message.isLike);
        /* bool is_dislike = 17; */
        if (message.isDislike !== false)
            writer.tag(17, WireType.Varint).bool(message.isDislike);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dto.Comment
 */
export const Comment = new Comment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebsiteVersion$Type extends MessageType<WebsiteVersion> {
    constructor() {
        super("dto.WebsiteVersion", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prompt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "html", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "created_by_user_info", kind: "message", T: () => UserInfo },
            { no: 5, name: "from_version_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "website_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "revoked_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "prompt_image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "preview_image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 12, name: "updated_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<WebsiteVersion>): WebsiteVersion {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.prompt = "";
        message.html = "";
        message.fromVersionId = "";
        message.websiteId = "";
        message.reason = "";
        message.promptImage = "";
        message.previewImage = "";
        if (value !== undefined)
            reflectionMergePartial<WebsiteVersion>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebsiteVersion): WebsiteVersion {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string prompt */ 2:
                    message.prompt = reader.string();
                    break;
                case /* string html */ 3:
                    message.html = reader.string();
                    break;
                case /* dto.UserInfo created_by_user_info */ 4:
                    message.createdByUserInfo = UserInfo.internalBinaryRead(reader, reader.uint32(), options, message.createdByUserInfo);
                    break;
                case /* string from_version_id */ 5:
                    message.fromVersionId = reader.string();
                    break;
                case /* string website_id */ 6:
                    message.websiteId = reader.string();
                    break;
                case /* string reason */ 7:
                    message.reason = reader.string();
                    break;
                case /* google.protobuf.Timestamp revoked_at */ 8:
                    message.revokedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.revokedAt);
                    break;
                case /* string prompt_image */ 9:
                    message.promptImage = reader.string();
                    break;
                case /* string preview_image */ 10:
                    message.previewImage = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 11:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 12:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebsiteVersion, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string prompt = 2; */
        if (message.prompt !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.prompt);
        /* string html = 3; */
        if (message.html !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.html);
        /* dto.UserInfo created_by_user_info = 4; */
        if (message.createdByUserInfo)
            UserInfo.internalBinaryWrite(message.createdByUserInfo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string from_version_id = 5; */
        if (message.fromVersionId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fromVersionId);
        /* string website_id = 6; */
        if (message.websiteId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.websiteId);
        /* string reason = 7; */
        if (message.reason !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.reason);
        /* google.protobuf.Timestamp revoked_at = 8; */
        if (message.revokedAt)
            Timestamp.internalBinaryWrite(message.revokedAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string prompt_image = 9; */
        if (message.promptImage !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.promptImage);
        /* string preview_image = 10; */
        if (message.previewImage !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.previewImage);
        /* google.protobuf.Timestamp created_at = 11; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 12; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message dto.WebsiteVersion
 */
export const WebsiteVersion = new WebsiteVersion$Type();
