// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as comment_service from './comment_service'

export class CommentServiceRealClient {
  constructor(private transport: Transport) {}

  async AddComment(
    request: comment_service.AddCommentReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<comment_service.AddCommentResp> {
    return this.transport.request({
      path: '/api/comment/add',
      method: 'POST',
      input: request,
      options
    })
  }


  async DeleteComment(
    request: comment_service.DeleteCommentReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<comment_service.DeleteCommentResp> {
    return this.transport.request({
      path: '/api/comment/delete',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetWebsiteCommentList(
    request: comment_service.GetWebsiteCommentListReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<comment_service.GetWebsiteCommentListResp> {
    return this.transport.request({
      path: '/api/comment/list',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetWebsiteCommentReplyList(
    request: comment_service.GetWebsiteCommentReplyListReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<comment_service.GetWebsiteCommentReplyListResp> {
    return this.transport.request({
      path: '/api/comment/list/reply',
      method: 'POST',
      input: request,
      options
    })
  }


  async UpdateCommentPreference(
    request: comment_service.UpdateCommentPreferenceReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<comment_service.UpdateCommentPreferenceResp> {
    return this.transport.request({
      path: '/api/comment/preference/update',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goCommentService = new CommentServiceRealClient(productionClient)
