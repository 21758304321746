// Generated code - do not modify
// @ts-nocheck
import { productionClient, localClient, Transport } from '@/utils/api/go_client'

import * as adapter_api from './adapter_api'

export class AdapterApiRealClient {
  constructor(private transport: Transport) {}

  async GenWebsite(
    request: adapter_api.GenWebsiteReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<adapter_api.GenWebsiteResp> {
    return this.transport.request({
      path: '/api/adapter/gen-website',
      method: 'POST',
      input: request,
      options
    })
  }


  async EditWebsite(
    request: adapter_api.EditWebsiteReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<adapter_api.EditWebsiteResp> {
    return this.transport.request({
      path: '/api/adapter/edit-website',
      method: 'POST',
      input: request,
      options
    })
  }


  async GetWebsiteSSE(
    request: adapter_api.GetWebsiteSSEReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<adapter_api.GetWebsiteSSEResp> {
    return this.transport.request({
      path: '/api/adapter/get-website-sse',
      method: 'GET',
      input: request,
      options
    })
  }


  async GetWebsitePresignedUrl(
    request: adapter_api.GetWebsitePresignedUrlReq,
    options?: {
      silent?: boolean | number[]
    }
  ): Promise<adapter_api.GetWebsitePresignedUrlResp> {
    return this.transport.request({
      path: '/api/adapter/get-website-presigned-url',
      method: 'POST',
      input: request,
      options
    })
  }
}

export const goAdapterApi = new AdapterApiRealClient(productionClient)
