// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "order/order_entities.proto" (package "order_entities", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message order_entities.TradeOrderExtra
 */
export interface TradeOrderExtra {
    /**
     * @generated from protobuf field: string stripe_customer_id = 1;
     */
    stripeCustomerId: string;
    /**
     * @generated from protobuf field: string stripe_session_id = 2;
     */
    stripeSessionId: string;
    /**
     * @generated from protobuf field: string stripe_payment_intent_id = 3;
     */
    stripePaymentIntentId: string;
    /**
     * @generated from protobuf field: string stripe_checkout_session_id = 4;
     */
    stripeCheckoutSessionId: string;
    /**
     * @generated from protobuf field: string stripe_product_id = 5;
     */
    stripeProductId: string;
    /**
     * @generated from protobuf field: string stripe_price_id = 6;
     */
    stripePriceId: string;
}
/**
 * @generated from protobuf message order_entities.TradeOrder
 */
export interface TradeOrder {
    /**
     * @generated from protobuf field: string order_id = 1;
     */
    orderId: string;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * 商品相关
     *
     * @generated from protobuf field: string product_id = 6;
     */
    productId: string;
    /**
     * 金额相关
     *
     * @generated from protobuf field: int64 amount = 10;
     */
    amount: bigint;
    /**
     * @generated from protobuf field: string currency = 12;
     */
    currency: string;
    /**
     * @generated from protobuf field: order_entities.TradeOrderStatus order_status = 13;
     */
    orderStatus: TradeOrderStatus;
    /**
     * @generated from protobuf field: order_entities.TradeOrderExtra extra = 14;
     */
    extra?: TradeOrderExtra;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 15;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 16;
     */
    updatedAt?: Timestamp;
}
/**
 * @generated from protobuf enum order_entities.TradeOrderStatus
 */
export enum TradeOrderStatus {
    /**
     * 未指定
     *
     * @generated from protobuf enum value: TRADE_ORDER_STATUS_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * 已创建
     *
     * @generated from protobuf enum value: TRADE_ORDER_STATUS_CREATED = 1;
     */
    CREATED = 1,
    /**
     * 支付待处理
     *
     * @generated from protobuf enum value: TRADE_ORDER_STATUS_PAYMENT_PENDING = 2;
     */
    PAYMENT_PENDING = 2,
    /**
     * 已支付
     *
     * @generated from protobuf enum value: TRADE_ORDER_STATUS_PAID = 3;
     */
    PAID = 3,
    /**
     * 已完成
     *
     * @generated from protobuf enum value: TRADE_ORDER_STATUS_COMPLETED = 4;
     */
    COMPLETED = 4,
    /**
     * 已取消
     *
     * @generated from protobuf enum value: TRADE_ORDER_STATUS_CANCELLED = 5;
     */
    CANCELLED = 5,
    /**
     * 支付失败
     *
     * @generated from protobuf enum value: TRADE_ORDER_STATUS_PAYMENT_FAILED = 6;
     */
    PAYMENT_FAILED = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class TradeOrderExtra$Type extends MessageType<TradeOrderExtra> {
    constructor() {
        super("order_entities.TradeOrderExtra", [
            { no: 1, name: "stripe_customer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stripe_session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "stripe_payment_intent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "stripe_checkout_session_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "stripe_product_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "stripe_price_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TradeOrderExtra>): TradeOrderExtra {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.stripeCustomerId = "";
        message.stripeSessionId = "";
        message.stripePaymentIntentId = "";
        message.stripeCheckoutSessionId = "";
        message.stripeProductId = "";
        message.stripePriceId = "";
        if (value !== undefined)
            reflectionMergePartial<TradeOrderExtra>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TradeOrderExtra): TradeOrderExtra {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripe_customer_id */ 1:
                    message.stripeCustomerId = reader.string();
                    break;
                case /* string stripe_session_id */ 2:
                    message.stripeSessionId = reader.string();
                    break;
                case /* string stripe_payment_intent_id */ 3:
                    message.stripePaymentIntentId = reader.string();
                    break;
                case /* string stripe_checkout_session_id */ 4:
                    message.stripeCheckoutSessionId = reader.string();
                    break;
                case /* string stripe_product_id */ 5:
                    message.stripeProductId = reader.string();
                    break;
                case /* string stripe_price_id */ 6:
                    message.stripePriceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TradeOrderExtra, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripe_customer_id = 1; */
        if (message.stripeCustomerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeCustomerId);
        /* string stripe_session_id = 2; */
        if (message.stripeSessionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.stripeSessionId);
        /* string stripe_payment_intent_id = 3; */
        if (message.stripePaymentIntentId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.stripePaymentIntentId);
        /* string stripe_checkout_session_id = 4; */
        if (message.stripeCheckoutSessionId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.stripeCheckoutSessionId);
        /* string stripe_product_id = 5; */
        if (message.stripeProductId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.stripeProductId);
        /* string stripe_price_id = 6; */
        if (message.stripePriceId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.stripePriceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_entities.TradeOrderExtra
 */
export const TradeOrderExtra = new TradeOrderExtra$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TradeOrder$Type extends MessageType<TradeOrder> {
    constructor() {
        super("order_entities.TradeOrder", [
            { no: 1, name: "order_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "product_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 12, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "order_status", kind: "enum", T: () => ["order_entities.TradeOrderStatus", TradeOrderStatus, "TRADE_ORDER_STATUS_"] },
            { no: 14, name: "extra", kind: "message", T: () => TradeOrderExtra },
            { no: 15, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 16, name: "updated_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TradeOrder>): TradeOrder {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orderId = "";
        message.userId = "";
        message.productId = "";
        message.amount = 0n;
        message.currency = "";
        message.orderStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<TradeOrder>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TradeOrder): TradeOrder {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string order_id */ 1:
                    message.orderId = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* string product_id */ 6:
                    message.productId = reader.string();
                    break;
                case /* int64 amount */ 10:
                    message.amount = reader.int64().toBigInt();
                    break;
                case /* string currency */ 12:
                    message.currency = reader.string();
                    break;
                case /* order_entities.TradeOrderStatus order_status */ 13:
                    message.orderStatus = reader.int32();
                    break;
                case /* order_entities.TradeOrderExtra extra */ 14:
                    message.extra = TradeOrderExtra.internalBinaryRead(reader, reader.uint32(), options, message.extra);
                    break;
                case /* google.protobuf.Timestamp created_at */ 15:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 16:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TradeOrder, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string order_id = 1; */
        if (message.orderId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.orderId);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string product_id = 6; */
        if (message.productId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.productId);
        /* int64 amount = 10; */
        if (message.amount !== 0n)
            writer.tag(10, WireType.Varint).int64(message.amount);
        /* string currency = 12; */
        if (message.currency !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.currency);
        /* order_entities.TradeOrderStatus order_status = 13; */
        if (message.orderStatus !== 0)
            writer.tag(13, WireType.Varint).int32(message.orderStatus);
        /* order_entities.TradeOrderExtra extra = 14; */
        if (message.extra)
            TradeOrderExtra.internalBinaryWrite(message.extra, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 15; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 16; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message order_entities.TradeOrder
 */
export const TradeOrder = new TradeOrder$Type();
