// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "order/order_service.proto" (package "order_service", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { OrderService } from "./order_service";
import type { CancelOrderResponse } from "./order_service";
import type { CancelOrderRequest } from "./order_service";
import type { GetOrderResponse } from "./order_service";
import type { GetOrderRequest } from "./order_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CreateOrderResponse } from "./order_service";
import type { CreateOrderRequest } from "./order_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service order_service.OrderService
 */
export interface IOrderServiceClient {
    /**
     * 创建订单
     *
     * @generated from protobuf rpc: CreateOrder(order_service.CreateOrderRequest) returns (order_service.CreateOrderResponse);
     */
    createOrder(input: CreateOrderRequest, options?: RpcOptions): UnaryCall<CreateOrderRequest, CreateOrderResponse>;
    /**
     * 获取订单
     *
     * @generated from protobuf rpc: GetOrder(order_service.GetOrderRequest) returns (order_service.GetOrderResponse);
     */
    getOrder(input: GetOrderRequest, options?: RpcOptions): UnaryCall<GetOrderRequest, GetOrderResponse>;
    /**
     * 取消订单
     *
     * @generated from protobuf rpc: CancelOrder(order_service.CancelOrderRequest) returns (order_service.CancelOrderResponse);
     */
    cancelOrder(input: CancelOrderRequest, options?: RpcOptions): UnaryCall<CancelOrderRequest, CancelOrderResponse>;
}
/**
 * @generated from protobuf service order_service.OrderService
 */
export class OrderServiceClient implements IOrderServiceClient, ServiceInfo {
    typeName = OrderService.typeName;
    methods = OrderService.methods;
    options = OrderService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * 创建订单
     *
     * @generated from protobuf rpc: CreateOrder(order_service.CreateOrderRequest) returns (order_service.CreateOrderResponse);
     */
    createOrder(input: CreateOrderRequest, options?: RpcOptions): UnaryCall<CreateOrderRequest, CreateOrderResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateOrderRequest, CreateOrderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取订单
     *
     * @generated from protobuf rpc: GetOrder(order_service.GetOrderRequest) returns (order_service.GetOrderResponse);
     */
    getOrder(input: GetOrderRequest, options?: RpcOptions): UnaryCall<GetOrderRequest, GetOrderResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrderRequest, GetOrderResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * 取消订单
     *
     * @generated from protobuf rpc: CancelOrder(order_service.CancelOrderRequest) returns (order_service.CancelOrderResponse);
     */
    cancelOrder(input: CancelOrderRequest, options?: RpcOptions): UnaryCall<CancelOrderRequest, CancelOrderResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CancelOrderRequest, CancelOrderResponse>("unary", this._transport, method, opt, input);
    }
}
