// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "token/token_api.proto" (package "token_api", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { TokenApi } from "./token_api";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetUserTokenTotalApiResp } from "./token_api";
import type { GetUserTokenTotalApiReq } from "./token_api";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service token_api.TokenApi
 */
export interface ITokenApiClient {
    /**
     * get user total token
     *
     * @generated from protobuf rpc: GetUserTokenTotalApi(token_api.GetUserTokenTotalApiReq) returns (token_api.GetUserTokenTotalApiResp);
     */
    getUserTokenTotalApi(input: GetUserTokenTotalApiReq, options?: RpcOptions): UnaryCall<GetUserTokenTotalApiReq, GetUserTokenTotalApiResp>;
}
/**
 * @generated from protobuf service token_api.TokenApi
 */
export class TokenApiClient implements ITokenApiClient, ServiceInfo {
    typeName = TokenApi.typeName;
    methods = TokenApi.methods;
    options = TokenApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * get user total token
     *
     * @generated from protobuf rpc: GetUserTokenTotalApi(token_api.GetUserTokenTotalApiReq) returns (token_api.GetUserTokenTotalApiResp);
     */
    getUserTokenTotalApi(input: GetUserTokenTotalApiReq, options?: RpcOptions): UnaryCall<GetUserTokenTotalApiReq, GetUserTokenTotalApiResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserTokenTotalApiReq, GetUserTokenTotalApiResp>("unary", this._transport, method, opt, input);
    }
}
