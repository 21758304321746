// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "adapter/adapter_api.proto" (package "adapter_api", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AdapterApi } from "./adapter_api";
import type { GetWebsitePresignedUrlResp } from "./adapter_api";
import type { GetWebsitePresignedUrlReq } from "./adapter_api";
import type { GetWebsiteSSEResp } from "./adapter_api";
import type { GetWebsiteSSEReq } from "./adapter_api";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { EditWebsiteResp } from "./adapter_api";
import type { EditWebsiteReq } from "./adapter_api";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GenWebsiteResp } from "./adapter_api";
import type { GenWebsiteReq } from "./adapter_api";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service adapter_api.AdapterApi
 */
export interface IAdapterApiClient {
    /**
     * @generated from protobuf rpc: GenWebsite(adapter_api.GenWebsiteReq) returns (adapter_api.GenWebsiteResp);
     */
    genWebsite(input: GenWebsiteReq, options?: RpcOptions): UnaryCall<GenWebsiteReq, GenWebsiteResp>;
    /**
     * @generated from protobuf rpc: EditWebsite(adapter_api.EditWebsiteReq) returns (adapter_api.EditWebsiteResp);
     */
    editWebsite(input: EditWebsiteReq, options?: RpcOptions): UnaryCall<EditWebsiteReq, EditWebsiteResp>;
    /**
     * @generated from protobuf rpc: GetWebsiteSSE(adapter_api.GetWebsiteSSEReq) returns (stream adapter_api.GetWebsiteSSEResp);
     */
    getWebsiteSSE(input: GetWebsiteSSEReq, options?: RpcOptions): ServerStreamingCall<GetWebsiteSSEReq, GetWebsiteSSEResp>;
    /**
     * @generated from protobuf rpc: GetWebsitePresignedUrl(adapter_api.GetWebsitePresignedUrlReq) returns (adapter_api.GetWebsitePresignedUrlResp);
     */
    getWebsitePresignedUrl(input: GetWebsitePresignedUrlReq, options?: RpcOptions): UnaryCall<GetWebsitePresignedUrlReq, GetWebsitePresignedUrlResp>;
}
/**
 * @generated from protobuf service adapter_api.AdapterApi
 */
export class AdapterApiClient implements IAdapterApiClient, ServiceInfo {
    typeName = AdapterApi.typeName;
    methods = AdapterApi.methods;
    options = AdapterApi.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GenWebsite(adapter_api.GenWebsiteReq) returns (adapter_api.GenWebsiteResp);
     */
    genWebsite(input: GenWebsiteReq, options?: RpcOptions): UnaryCall<GenWebsiteReq, GenWebsiteResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GenWebsiteReq, GenWebsiteResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditWebsite(adapter_api.EditWebsiteReq) returns (adapter_api.EditWebsiteResp);
     */
    editWebsite(input: EditWebsiteReq, options?: RpcOptions): UnaryCall<EditWebsiteReq, EditWebsiteResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<EditWebsiteReq, EditWebsiteResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWebsiteSSE(adapter_api.GetWebsiteSSEReq) returns (stream adapter_api.GetWebsiteSSEResp);
     */
    getWebsiteSSE(input: GetWebsiteSSEReq, options?: RpcOptions): ServerStreamingCall<GetWebsiteSSEReq, GetWebsiteSSEResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteSSEReq, GetWebsiteSSEResp>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWebsitePresignedUrl(adapter_api.GetWebsitePresignedUrlReq) returns (adapter_api.GetWebsitePresignedUrlResp);
     */
    getWebsitePresignedUrl(input: GetWebsitePresignedUrlReq, options?: RpcOptions): UnaryCall<GetWebsitePresignedUrlReq, GetWebsitePresignedUrlResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsitePresignedUrlReq, GetWebsitePresignedUrlResp>("unary", this._transport, method, opt, input);
    }
}
