// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "web_api/website_service.proto" (package "website", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { WebsiteService } from "./website_service";
import type { GetWebsiteHistoryListResp } from "./website_service";
import type { GetWebsiteHistoryListReq } from "./website_service";
import type { EvalWebsiteResp } from "./website_service";
import type { EvalWebsiteReq } from "./website_service";
import type { GetWebsiteMetricCountResp } from "./website_service";
import type { GetWebsiteMetricCountReq } from "./website_service";
import type { UpdateUserPreferenceResp } from "./website_service";
import type { UpdateUserPreferenceReq } from "./website_service";
import type { GetWebsiteUserPreferenceResp } from "./website_service";
import type { GetWebsiteUserPreferenceReq } from "./website_service";
import type { GetRelativeWebsiteResp } from "./website_service";
import type { GetRelativeWebsiteReq } from "./website_service";
import type { GetWebsiteListResp } from "./website_service";
import type { GetWebsiteListReq } from "./website_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetWebsiteResp } from "./website_service";
import type { GetWebsiteReq } from "./website_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service website.WebsiteService
 */
export interface IWebsiteServiceClient {
    /**
     * @generated from protobuf rpc: GetWebsite(website.GetWebsiteReq) returns (website.GetWebsiteResp);
     */
    getWebsite(input: GetWebsiteReq, options?: RpcOptions): UnaryCall<GetWebsiteReq, GetWebsiteResp>;
    /**
     * @generated from protobuf rpc: GetWebsiteList(website.GetWebsiteListReq) returns (website.GetWebsiteListResp);
     */
    getWebsiteList(input: GetWebsiteListReq, options?: RpcOptions): UnaryCall<GetWebsiteListReq, GetWebsiteListResp>;
    /**
     * @generated from protobuf rpc: GetRelativeWebsiteList(website.GetRelativeWebsiteReq) returns (website.GetRelativeWebsiteResp);
     */
    getRelativeWebsiteList(input: GetRelativeWebsiteReq, options?: RpcOptions): UnaryCall<GetRelativeWebsiteReq, GetRelativeWebsiteResp>;
    /**
     * @generated from protobuf rpc: GetWebsiteUserPreference(website.GetWebsiteUserPreferenceReq) returns (website.GetWebsiteUserPreferenceResp);
     */
    getWebsiteUserPreference(input: GetWebsiteUserPreferenceReq, options?: RpcOptions): UnaryCall<GetWebsiteUserPreferenceReq, GetWebsiteUserPreferenceResp>;
    /**
     * @generated from protobuf rpc: UpdateUserPreference(website.UpdateUserPreferenceReq) returns (website.UpdateUserPreferenceResp);
     */
    updateUserPreference(input: UpdateUserPreferenceReq, options?: RpcOptions): UnaryCall<UpdateUserPreferenceReq, UpdateUserPreferenceResp>;
    /**
     * @generated from protobuf rpc: GetWebsiteMetricCount(website.GetWebsiteMetricCountReq) returns (website.GetWebsiteMetricCountResp);
     */
    getWebsiteMetricCount(input: GetWebsiteMetricCountReq, options?: RpcOptions): UnaryCall<GetWebsiteMetricCountReq, GetWebsiteMetricCountResp>;
    /**
     * @generated from protobuf rpc: EvalWebsite(website.EvalWebsiteReq) returns (website.EvalWebsiteResp);
     */
    evalWebsite(input: EvalWebsiteReq, options?: RpcOptions): UnaryCall<EvalWebsiteReq, EvalWebsiteResp>;
    /**
     * @generated from protobuf rpc: GetWebsiteHistoryList(website.GetWebsiteHistoryListReq) returns (website.GetWebsiteHistoryListResp);
     */
    getWebsiteHistoryList(input: GetWebsiteHistoryListReq, options?: RpcOptions): UnaryCall<GetWebsiteHistoryListReq, GetWebsiteHistoryListResp>;
}
/**
 * @generated from protobuf service website.WebsiteService
 */
export class WebsiteServiceClient implements IWebsiteServiceClient, ServiceInfo {
    typeName = WebsiteService.typeName;
    methods = WebsiteService.methods;
    options = WebsiteService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetWebsite(website.GetWebsiteReq) returns (website.GetWebsiteResp);
     */
    getWebsite(input: GetWebsiteReq, options?: RpcOptions): UnaryCall<GetWebsiteReq, GetWebsiteResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteReq, GetWebsiteResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWebsiteList(website.GetWebsiteListReq) returns (website.GetWebsiteListResp);
     */
    getWebsiteList(input: GetWebsiteListReq, options?: RpcOptions): UnaryCall<GetWebsiteListReq, GetWebsiteListResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteListReq, GetWebsiteListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRelativeWebsiteList(website.GetRelativeWebsiteReq) returns (website.GetRelativeWebsiteResp);
     */
    getRelativeWebsiteList(input: GetRelativeWebsiteReq, options?: RpcOptions): UnaryCall<GetRelativeWebsiteReq, GetRelativeWebsiteResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRelativeWebsiteReq, GetRelativeWebsiteResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWebsiteUserPreference(website.GetWebsiteUserPreferenceReq) returns (website.GetWebsiteUserPreferenceResp);
     */
    getWebsiteUserPreference(input: GetWebsiteUserPreferenceReq, options?: RpcOptions): UnaryCall<GetWebsiteUserPreferenceReq, GetWebsiteUserPreferenceResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteUserPreferenceReq, GetWebsiteUserPreferenceResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateUserPreference(website.UpdateUserPreferenceReq) returns (website.UpdateUserPreferenceResp);
     */
    updateUserPreference(input: UpdateUserPreferenceReq, options?: RpcOptions): UnaryCall<UpdateUserPreferenceReq, UpdateUserPreferenceResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateUserPreferenceReq, UpdateUserPreferenceResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWebsiteMetricCount(website.GetWebsiteMetricCountReq) returns (website.GetWebsiteMetricCountResp);
     */
    getWebsiteMetricCount(input: GetWebsiteMetricCountReq, options?: RpcOptions): UnaryCall<GetWebsiteMetricCountReq, GetWebsiteMetricCountResp> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteMetricCountReq, GetWebsiteMetricCountResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EvalWebsite(website.EvalWebsiteReq) returns (website.EvalWebsiteResp);
     */
    evalWebsite(input: EvalWebsiteReq, options?: RpcOptions): UnaryCall<EvalWebsiteReq, EvalWebsiteResp> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<EvalWebsiteReq, EvalWebsiteResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWebsiteHistoryList(website.GetWebsiteHistoryListReq) returns (website.GetWebsiteHistoryListResp);
     */
    getWebsiteHistoryList(input: GetWebsiteHistoryListReq, options?: RpcOptions): UnaryCall<GetWebsiteHistoryListReq, GetWebsiteHistoryListResp> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWebsiteHistoryListReq, GetWebsiteHistoryListResp>("unary", this._transport, method, opt, input);
    }
}
