// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs
// @generated from protobuf file "token/token_entity.proto" (package "token", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message token.TokenOperation
 */
export interface TokenOperation {
    /**
     * @generated from protobuf field: int32 op_type = 1;
     */
    opType: number; // 如果op_type==0，则需要biz_no参数
    /**
     * @generated from protobuf field: int32 token = 2;
     */
    token: number;
    /**
     * @generated from protobuf field: int32 token_type = 3;
     */
    tokenType: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 status = 5;
     */
    status: number; // 如果status==1、2、3，则需要finished_at参数
    /**
     * @generated from protobuf field: optional string biz_no = 6;
     */
    bizNo?: string;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp finished_at = 7;
     */
    finishedAt?: Timestamp;
}
/**
 * @generated from protobuf message token.TokenDetail
 */
export interface TokenDetail {
    /**
     * @generated from protobuf field: int32 total_tokens = 1;
     */
    totalTokens: number;
    /**
     * @generated from protobuf field: int32 used_tokens = 2;
     */
    usedTokens: number;
    /**
     * @generated from protobuf field: int32 frozen_tokens = 3;
     */
    frozenTokens: number;
    /**
     * @generated from protobuf field: int32 status = 4;
     */
    status: number;
    /**
     * @generated from protobuf field: int32 token_type = 5;
     */
    tokenType: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp create_at = 6;
     */
    createAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp effective_at = 7;
     */
    effectiveAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp expired_at = 8;
     */
    expiredAt?: Timestamp;
}
/**
 * @generated from protobuf enum token.TokenType
 */
export enum TokenType {
    /**
     * @generated from protobuf enum value: TOKEN_TYPE_UNKNOWN = 0;
     */
    TOKEN_TYPE_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: TOKEN_GENERATE_WEBSITE = 1;
     */
    TOKEN_GENERATE_WEBSITE = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class TokenOperation$Type extends MessageType<TokenOperation> {
    constructor() {
        super("token.TokenOperation", [
            { no: 1, name: "op_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "token", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "token_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "biz_no", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "finished_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TokenOperation>): TokenOperation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.opType = 0;
        message.token = 0;
        message.tokenType = 0;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<TokenOperation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenOperation): TokenOperation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 op_type */ 1:
                    message.opType = reader.int32();
                    break;
                case /* int32 token */ 2:
                    message.token = reader.int32();
                    break;
                case /* int32 token_type */ 3:
                    message.tokenType = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* int32 status */ 5:
                    message.status = reader.int32();
                    break;
                case /* optional string biz_no */ 6:
                    message.bizNo = reader.string();
                    break;
                case /* optional google.protobuf.Timestamp finished_at */ 7:
                    message.finishedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.finishedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TokenOperation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 op_type = 1; */
        if (message.opType !== 0)
            writer.tag(1, WireType.Varint).int32(message.opType);
        /* int32 token = 2; */
        if (message.token !== 0)
            writer.tag(2, WireType.Varint).int32(message.token);
        /* int32 token_type = 3; */
        if (message.tokenType !== 0)
            writer.tag(3, WireType.Varint).int32(message.tokenType);
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 status = 5; */
        if (message.status !== 0)
            writer.tag(5, WireType.Varint).int32(message.status);
        /* optional string biz_no = 6; */
        if (message.bizNo !== undefined)
            writer.tag(6, WireType.LengthDelimited).string(message.bizNo);
        /* optional google.protobuf.Timestamp finished_at = 7; */
        if (message.finishedAt)
            Timestamp.internalBinaryWrite(message.finishedAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.TokenOperation
 */
export const TokenOperation = new TokenOperation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TokenDetail$Type extends MessageType<TokenDetail> {
    constructor() {
        super("token.TokenDetail", [
            { no: 1, name: "total_tokens", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "used_tokens", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "frozen_tokens", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "token_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "create_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "effective_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "expired_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TokenDetail>): TokenDetail {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalTokens = 0;
        message.usedTokens = 0;
        message.frozenTokens = 0;
        message.status = 0;
        message.tokenType = 0;
        if (value !== undefined)
            reflectionMergePartial<TokenDetail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenDetail): TokenDetail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 total_tokens */ 1:
                    message.totalTokens = reader.int32();
                    break;
                case /* int32 used_tokens */ 2:
                    message.usedTokens = reader.int32();
                    break;
                case /* int32 frozen_tokens */ 3:
                    message.frozenTokens = reader.int32();
                    break;
                case /* int32 status */ 4:
                    message.status = reader.int32();
                    break;
                case /* int32 token_type */ 5:
                    message.tokenType = reader.int32();
                    break;
                case /* google.protobuf.Timestamp create_at */ 6:
                    message.createAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createAt);
                    break;
                case /* google.protobuf.Timestamp effective_at */ 7:
                    message.effectiveAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.effectiveAt);
                    break;
                case /* google.protobuf.Timestamp expired_at */ 8:
                    message.expiredAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiredAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TokenDetail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 total_tokens = 1; */
        if (message.totalTokens !== 0)
            writer.tag(1, WireType.Varint).int32(message.totalTokens);
        /* int32 used_tokens = 2; */
        if (message.usedTokens !== 0)
            writer.tag(2, WireType.Varint).int32(message.usedTokens);
        /* int32 frozen_tokens = 3; */
        if (message.frozenTokens !== 0)
            writer.tag(3, WireType.Varint).int32(message.frozenTokens);
        /* int32 status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).int32(message.status);
        /* int32 token_type = 5; */
        if (message.tokenType !== 0)
            writer.tag(5, WireType.Varint).int32(message.tokenType);
        /* google.protobuf.Timestamp create_at = 6; */
        if (message.createAt)
            Timestamp.internalBinaryWrite(message.createAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp effective_at = 7; */
        if (message.effectiveAt)
            Timestamp.internalBinaryWrite(message.effectiveAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp expired_at = 8; */
        if (message.expiredAt)
            Timestamp.internalBinaryWrite(message.expiredAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message token.TokenDetail
 */
export const TokenDetail = new TokenDetail$Type();
